import { firebase } from "../firebase-config";
import {COLLECTIONS} from '../config';
const collection = COLLECTIONS.GROUPS;

export const getGroupsList = (formData, onSuccess, onError) => {
  firebase
    .firestore()
    .collection(collection)
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({
          groupId: doc.id,
          ...data,
        });
      });
      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
      onError && onError(error);
    });
};

export async function getOneGroup(id, onSuccess, onError) {
  try {
    firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          onError && onError({ status: false, error: "Group not found" });
        }
        let groupId = doc.id;
        let data = doc.data();
        onSuccess &&
          onSuccess({ status: true, data: { ...data, groupId: groupId } });
      })
      .catch((error) => {
        onError && onError({ status: false, error: error });
      });
  } catch (res_1) {
    onError && onError(res_1);
  }
}

export async function addGroup(formData, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  firebase
    .firestore()
    .collection(collection)
    .add({
      ...formData,
      employeeId: [],
      createdBy: currentUserId,
      createdAt: new Date().getTime(),
    })
    .then(
      async (doc) => {
        const responseGroupItem = formData;
        responseGroupItem.groupId = doc.id;
        onSuccess && onSuccess({ status: true, data: responseGroupItem });
      },
      (error) => {
        console.log(`ERROR: ${collection} ${error}`);
        onError({ status: false, error: error });
      }
    )
    .catch((e) => {
      console.log(`ERROR: ${collection} ${e}`);
      onError({ status: false, error: e });
    });
}

export async function updateGroup(id, formData, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  let document = await firebase.firestore().collection(collection).doc(id);
  const docContent = {
    ...formData,
    updatedAt: new Date().getTime(),
    updatedBy: currentUserId,
  };
  document
    .set(docContent, { merge: true })
    .then(async (doc) => {
      onSuccess &&
        onSuccess({
          status: true,
          message: "Updated successfully",
          response: doc,
        });
    })
    .catch((error) => {
      if (error.code === 5) {
        onError && onError({ status: false, message: "Not Found" });
      }
      onError && onError({ status: false, error: error.code });
    });
}

export async function deleteGroup(id, onSuccess, onError) {
  const document = firebase.firestore().collection(collection).doc(id);
  document
    .get()
    .then((doc) => {
      if (!doc.exists) {
        onError && onError({ status: false, error: "Group not found" });
      }
      let data = doc.data();
      if(data?.employeeId && data?.employeeId?.length > 0){
        data?.employeeId?.map(async (empid) =>{
          await firebase.firestore().collection(COLLECTIONS.EMPLOYEE).doc(empid).set({
            group: firebase.firestore.FieldValue.arrayRemove(id)
          },{merge:true});
          return empid;
        })
      }
      return document.delete();
    })
    .then(() => {
      onSuccess && onSuccess({ status: true, message: "Delete successfull" });
    })
    .catch((err) => {
      onError &&
        onError({
          status: false,
          error: err.code,
        });
    });
}
