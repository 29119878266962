const Columns = [
    // { id: "checkbox", numeric: true, label: "", isSortable: false },
    {
        id: "itemName",
        numeric: false,
        disablePadding: true,
        label: "Supplies Name",
        isSortable: true,
    },
    {
        id: "priority",
        numeric: false,
        disablePadding: false,
        label: "Priority",
        isSortable: true,
    },
    {
        id: "dateOfRequest",
        numeric: false,
        disablePadding: true,
        label: "Last Date of Item Request",
        isSortable: true,
    },
    {
        id: "clientName",
        numeric: false,
        disablePadding: false,  
        label: "Client Name",
        isSortable: true,
    },   
    { id: "action", numeric: false, disablePadding: true, label: "Action", className:" pr-30" },
];

const Rows = [
];

export { Columns, Rows };
  