import { firebase, firebase_auth } from './firebase-config';
import axiosInstance from './axios';
import { getLoginToken } from './storage';
import { BaseUrl, DOMAINURL } from './config';
// const firestore = firebase.firestore();
// const settings = { timestampsInSnapshots: true };
// firestore.settings(settings);

/**
 * so this function is called when the authentication state changes
 * in the application, a side effect of that is that we need to get
 * the rest of the user data from the user collection, that is
 * done with the _handleAuthedUser callback
 */
export const authCheck = async (_handleAuthedUser) => {
  return new Promise((resolve) => {
    // Listen for authentication state to change.
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user != null) {
        console.log('We are authenticated now!');

        return resolve(await _handleAuthedUser(user));
      } else {
        console.log('We did not authenticate.');
        _handleAuthedUser(null);
        return resolve(null);
      }
    });
  });
};

/**
 *
 * @param {*} email
 * @param {*} password
 */
export const loginWithEmail = async (email, password) => {
  let user = {};
  let loginres = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((userCredential) => {
      
      user = userCredential.user;
      return userCredential.user.getIdToken();
    })
    .then((token) => {
      return { userId: user?.uid, user: user, token: token };
    })
    .catch((error) => {
      return error;
    });
  return loginres;
};
/**
 *
 * @returns data of current logged in user from users collection
 */
export const getCurrentUser = async () => {
  let user = await firebase.auth().currentUser;
  let token = await user.getIdToken();
  let userData = {};
  if (user) {
    await firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          //   console.log("Document data:", doc.data());
          userData = await { ...doc.data(), id: user.uid };
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!', user.uid);
        }
        return { user, userData, token };
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }

  return { user, userData, token };
};

export const getCurrentUserToken = async (user) => {
  let token = await user.getIdToken();
  return token;
};
/**
 * Log out user
 */
export const logOut = () => {
  return firebase.auth().signOut();
};

/**
 *
 * @param {*} userInfo.lastName
 * @param {*} userInfo.firstName
 * @param {*} userInfo.email
 * @param {*} userInfo.password
 */
export const registerUser = (userInfo) => {
  let newcreateduser = {};
  return firebase
    .auth()
    .createUserWithEmailAndPassword(userInfo.email, userInfo.password)
    .then((newUser) => {
      newcreateduser = newUser;

      let { email, firstName, lastName, role, createdAt, phonenumber } =
        userInfo;

      return firebase
        .firestore()
        .collection('users')
        .doc(newcreateduser.user.uid)
        .set({
          email: email ? email : '',
          firstName: firstName ? firstName : '',
          lastName: lastName ? lastName : '',
          role: role ? role : 'Employee',
          createdAt: createdAt ? createdAt : '',
          phoneNumber: phonenumber ? phonenumber : '',
        })
        .then(() => {
          return { ...newcreateduser.user, firstName, lastName };
        })
        .catch((error) => {
          return { status: false, error };
        });
    });
};

/**
 * register new user to firebase auth and users collection (not used for now manage from cloud function)
 * @param {*} newUser
 * @returns
 */
export async function firebasesignupUser(newUser) {
  var checkUser = await firebase
    .firestore()
    .collection('users')
    .doc(newUser.email)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return { status: false, message: 'this username is already taken' };
      } else {
        return { status: true };
      }
    });
  if (checkUser?.status === true) {
    return firebase_auth
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((newCreatedUser) => {
        let { email, firstName, lastName, role, createdAt, phonenumber } =
          newUser;

        return firebase
          .firestore()
          .collection('users')
          .doc(newCreatedUser.user.uid)
          .set({
            email: email ? email : '',
            firstName: firstName ? firstName : '',
            lastName: lastName ? lastName : '',
            role: role ? role : '',
            createdAt: createdAt ? createdAt : '',
            phoneNumber: phonenumber ? phonenumber : '',
          })
          .then(() => {
            return { ...newCreatedUser.user, firstName, lastName };
          })
          .catch((error) => {
            return { status: false, error };
          });
      });
  }

  // const fuserdata = await firebase
  //   .auth()
  //   .createUserWithEmailAndPassword(newUser.email, newUser.password);
  // userId = fuserdata.user.uid;
  // token = await fuserdata.user.getIdToken();
  // const userCredentials = {
  //   firstName: newUser.firstName,
  //   lastName: newUser.lastName,
  //   username: newUser.username,
  //   phoneNumber: newUser.phoneNumber,
  //   country: newUser.country,
  //   email: newUser.email,
  //   createdAt: new Date().toISOString(),
  //   userId,
  //   role: newUser.role,
  // };
  // const createdUser = await firebase
  //   .firestore()
  //   .collection("users")
  //   .add(userCredentials)
  //   .then((doc) => {
  //     var doc_user_id = doc.id;
  //     var userData = newUser;
  //     return {
  //       status: true,
  //       message: "User Created!",
  //       token,
  //       UserData: { doc_user_id, ...userData },
  //     };
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //     return { status: false, error: "Something went wrong" };
  //   });
  //     return createdUser;
  //   } else {
  //     return checkUser;
  //   }
}

/**
 * Sent Forgot password email for give email user
 * @param {*} email
 * @param {*} onSuccess
 * @param {*} onError
 */
export const forgotPasswordUser = async (email, onSuccess, onError) => {
  const userRef = firebase
    .firestore()
    .collection('users')
    .where('email', '==', email)
    .get()
    .then((snap) => {
      snap.forEach((doc) => {
        return doc.data();
      });
    });

  if (userRef.empty) {
    onError &&
      onError({ status: false, message: 'No User found with this email' });
  } else {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        // Password reset email sent!
        onSuccess &&
          onSuccess({ status: true, message: 'Password reset email sent!' });
      })
      .catch((error) => {
        var errorMessage = error.message;
        onError && onError({ status: false, message: errorMessage });
      });
  }
};

export const forgotPasswordAdmin = async (
  email,
  phoneNumber,
  onSuccess,
  onError
) => {
  let user = {};
  const userRef =  await firebase
    .firestore()
    .collection('users')
    .where('email', '==', email)
    .get()
    .then((snap) => {
      
      snap.forEach((doc) => {
        // return doc.data();
        user = doc.data();
      });
    }).catch((error)=> {
      console.log(userRef);
    });
    
  if (Object.keys(user).length === 0) {
    onError &&
      onError({ status: false, message: 'No User found with this email' });
  } else {
    
    let actionCodeSettings = {
      url: `https://${DOMAINURL}/`};

      if(user.employeerole === "Client Team"){
        actionCodeSettings = {};
      }

      firebase
      .auth()
      .sendPasswordResetEmail(email, actionCodeSettings)
      .then(async () => {
        // Password reset email sent!
        const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
        await axiosInstance.post(
          `${BaseUrl}sendSmsText`,
          {
            phoneNumber: [phoneNumber],
            textMessage: 
            `Hello,\n\nYour ProTeams password has been reset. Please check ${email} for your password reset link`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              withCredentials: true,
              ...authHeaders,
            },
          }
        );

        onSuccess &&
          onSuccess({ status: true, message: 'Password reset email sent!' });
      })
      .catch((error) => {
        var errorMessage = error.message;
        onError && onError({ status: false, message: errorMessage });
      });
  }
};

export const ResendAppLink = async (
  email,
  phoneNumber,
  firstName,
  password,
  onSuccess,
  onError
) => {
  // Password reset email sent!
  const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
  await axiosInstance.post(
    `${BaseUrl}sendEmailData`,
    {
      email: email,
      subject: `App Invitation From ProTeams.io `,
      contenttxt:
        `<p>Hello ` +
        firstName +
        `,</p>

    <p>You've been invited to the ProTeams mobile app. Please click on the https://${DOMAINURL}/apps/ to download the app from iOS or Android stores. Once you have installed the app you can you login by using the following details: </p>
    <p>User name:<br />` +
                email +
                `</p> 
               <p>Password:<br />` +
                password +
                `</p>
                <p></p>
                <p></p>
                <p>VBS Support Team</p>`,
   
    },
    {
      headers: {
        'Content-Type': 'application/json',
        withCredentials: true,
        ...authHeaders,
      },
    }
  );

  await axiosInstance.post(
    `${BaseUrl}sendSmsText`,
    {
      phoneNumber: [phoneNumber],
      textMessage: 
      `Hello ${firstName},\n\nYou're invited to the ProTeams App! Download it - https://${DOMAINURL}/apps/\n\nYour ID & password were sent to ${email}`,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        withCredentials: true,
        ...authHeaders,
      },
    }
  );

  onSuccess && onSuccess({ status: true, message: 'Resend Invitation sent!' });
};

/**
 * Returns Current login user profile data
 */
export const getUserProfile = (token, onSuccess, onError) => {
  let user = firebase.auth().currentUser;

  if (user) {
    var userRef = firebase.firestore().collection('users').doc(user.uid);
    userRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log('Document data:', doc.data());
          onSuccess &&
            onSuccess({ status: true, data: { ...doc.data(), id: user.uid } });
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!', user.uid);
          onError && onError({ status: false, data: 'No such document!' });
        }
      })
      .catch((error) => {
        onError && onError({ status: false, data: 'No such document!', error });
        console.log('Error getting document:', error);
      });
  } else {
    onError && onError({ status: false, data: 'No such document!' });
  }
};

/**
 *
 * @param {*} blob
 */
export const uploadImage = (blob) => {
  return new Promise((resolve, reject) => {
    let currentUserId = firebase.auth().currentUser.uid;
    const ref = firebase
      .storage()
      .ref(currentUserId)
      .child(new Date().getTime() + '-' + currentUserId + '.jpeg');

    const task = ref.put(blob);

    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>
        console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      (error) => {
        console.log('error', error);
        return reject(error);
      },
      (result) => {
        return resolve({
          url: task.snapshot.downloadURL,
          contentType: task.snapshot.metadata.contentType,
          name: task.snapshot.metadata.name,
          size: task.snapshot.metadata.size,
        });
      }
    );
  });
};

export const updatepasswordem = (code, newPassword, onSuccess, onError) => {
  firebase
    .auth()
    .confirmPasswordReset(code, newPassword)
    .then(() => {
      onSuccess && onSuccess({ status: true, message: 'Password Updated!' });
    })
    .catch((error) => {
      var errorMessage = error.message;
      onError && onError({ status: false, message: errorMessage });
    });
};
