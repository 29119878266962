import axiosInstance from '../axios';
import { getLoginToken } from '../storage';
import { BaseUrl } from '../config';
import { firebase } from '../firebase-config';
const tz = require("moment-timezone");
const moment = require("moment");

const defaultHeaders = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  withCredentials: true,
};

export async function acuitygetEmployees(formData, onSuccess, onError) {
  const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
  try {
    const res = await axiosInstance.get(`${BaseUrl}acuitygetEmployees`, {
      params: { ...formData },
      headers: { ...defaultHeaders, ...authHeaders },
    });
    console.log(res);
    onSuccess && onSuccess(res);
  } catch (res_1) {
    onError && onError(res_1);
  }
}

/**
 * Get Appontments list from acuity throu cloud function
 * @param {*} formData : may be use to pass data for filter
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function acuitygetAppointments(formData, onSuccess, onError) {
  const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
  try {
    const res = await axiosInstance.get(
      `${BaseUrl}acuitygetAppointmentsAdminnew`,
      {
        params: { ...formData },
        headers: { ...defaultHeaders, ...authHeaders },
      }
    );
    
    let resultsStore = [];
    let colRef = firebase.firestore().collection("timesheets");
    await colRef
      .where("appointmentDate", "in", [moment(formData.minDate).format("MMMM D, YYYY")])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          resultsStore.push({ id: doc.id, ...data });
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
      
      const resData = res?.data?.data;
      const cancelledAppts = [];
      const filterData = resData?.filter((acuityAppt) => {
        const findEle = resultsStore.find(
          (storeAppt) => {
            if(storeAppt?.apoointmentDetails?.canceled){
              cancelledAppts.push(storeAppt.id);
            }
            return acuityAppt.id.toString() === storeAppt.appointmentId.toString();
          }
        );

        if (findEle) {
          if (acuityAppt?.noShow) {
            cancelledAppts.push(findEle.id);
          }
          return false;
        }
        if (acuityAppt?.noShow) return false;

        return true;
      });


      cancelledAppts?.map((storApptId) => {
        const refToDel = firebase
          .firestore()
          .collection("timesheets")
          .doc(storApptId);
        refToDel.delete();
        return storApptId;
      });

      filterData?.map(async (appointmentData) => {
        const employee = [];
        await firebase
          .firestore()
          .collection("users")
          .where("acuityId", "in", [
            appointmentData.calendarID,
            appointmentData.calendarID.toString(),
          ])
          // .where("role", "==", "Employee")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let data = doc.data();
              employee.push({ id: doc.id, ...data });
            });
          })
          .catch((error) => {
            console.log("Error on User fetching>>", error);
          });
        // console.log(employee);
        // console.log("3");
        if (employee.length > 0) {
          const clients = [];
          await firebase
            .firestore()
            .collection("clientsNew")
            .where(
              "firstName",
              "==",
              appointmentData.firstName ? appointmentData.firstName.trim() : ""
            )
            .where(
              "lastName",
              "==",
              appointmentData.lastName ? appointmentData.lastName.trim() : ""
            )
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                let data = doc.data();
                clients.push({ id: doc.id, ...data });
              });
            })
            .catch((error) => {
              console.log("Error on client fetching>>", error);
            });
          if (clients.length <= 0) {
            await firebase
              .firestore()
              .collection("clientsNew")
              .where("clientName", "==", appointmentData.firstName)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  let data = doc.data();
                  clients.push({ id: doc.id, ...data });
                });
              })
              .catch((error) => {
                console.log("Error on client fetching2>>", error);
              });
          }

          if (clients.length > 0 && !appointmentData?.noShow) {
            const clientData = clients[0];
            const shiftFields = appointmentData.forms[0].values;
            let ShiftName = "Morning";
            shiftFields.forEach((item) => {
              if (item.name.trim() === "ShiftName" || item.name.trim() === "Shift Name") {
                ShiftName = item.value;
              }
            });

            let clientShiftData = {};
            let ShiftId = "";
            for (let shiftDataKey in clientData.shiftData) {
              const shiftData = clientData.shiftData[shiftDataKey];
              for (let shift in shiftData) {
                if (shiftData[shift].title === ShiftName) {
                  ShiftId = shiftDataKey;
                  clientShiftData = { ...clientShiftData, ...shiftData };
                }
              }
            }
            
            let finalResult = [];
            await colRef
              .where("appointmentId", "in", [
                appointmentData.id,
                appointmentData.id.toString(),
              ])
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  let data = doc.data();
                  finalResult.push({ id: doc.id, ...data });
                });
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
              });
            if (finalResult.length > 0) {
              let timeSheetData = {
                appointmentId: appointmentData.id.toString(),
                clientId: clients[0].id,
                email: employee[0].email,
                isWorking: false,
                completed: false,
                lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                location: { lat: 32.0, lng: 32.0 },
                shiftId: ShiftId,
                tasks: clientShiftData,
                userId: employee[0].id,
                appointmentDateTime: appointmentData.datetime,
                appointmentDate: appointmentData.date,
                appointmentDuration: appointmentData.duration,
                apoointmentDetails: appointmentData,
              };
              
              await firebase.firestore().collection("timesheets").doc(finalResult[0].id).set(timeSheetData);
              
              return;
            }

            let timeSheetData = {
              appointmentId: appointmentData.id.toString(),
              clientId: clients[0].id,
              email: employee[0].email,
              isWorking: false,
              completed: false,
              lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              location: { lat: 32.0, lng: 32.0 },
              shiftId: ShiftId,
              tasks: clientShiftData,
              userId: employee[0].id,
              appointmentDateTime: appointmentData.datetime,
              appointmentDate: appointmentData.date,
              appointmentDuration: appointmentData.duration,
              apoointmentDetails: appointmentData,
            };

            const id = `${timeSheetData.appointmentId}-${
              timeSheetData.clientId
            }-${timeSheetData.shiftId}-${timeSheetData.userId}-${
              new Date().toISOString().split("T")[0]
            }`;
            timeSheetData.createdAt =
              firebase.firestore.FieldValue.serverTimestamp();
            const ref = firebase.firestore().collection("timesheets").doc(id);

            await ref.set(timeSheetData, { merge: true });
          }
        }
      });


    onSuccess && onSuccess(res);

  } catch (res_1) {
    console.log("res_1>>", res_1);
    onError && onError(res_1);
  }
}
