const Columns = [
  // { id: "checkbox", numeric: true, label: "", isSortable: false },
  {
    id: "createdOn",
    numeric: false,
    disablePadding: false,
    label: "Date",
    isSortable: true,
  },
  {
    id: "group",
    numeric: false,
    disablePadding: false,
    label: "Group",
    isSortable: true,
  },
  {
    id: "Type",
    numeric: false,
    disablePadding: false,
    label: "Method",
    isSortable: true,
  },
  {
    id: "Message",
    numeric: false,
    disablePadding: false,
    label: "Notification Message",
    isSortable: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Action",
    rowclassName: "text-right",
    className: "text-right pr-30",
  },
];

const Rows = [
  {
    date: "june 5, 2021",
    group: "Auditors",
    method: "In app",
    message: "Lorem ipsum dolor sit amet text heare dummy text here",
  },
  {
    date: "june 5, 2021",
    group: "Auditors",
    method: "In app",
    message: "Lorem ipsum sit amet text heare dummy text here",
  },
  {
    date: "june 5, 2021",
    group: "Auditors",
    method: "In app",
    message: "Lorem ipsum dolor sit amet text sit amet text heare dummy text here",
  },
  {
    date: "june 5, 2021",
    group: "Auditors",
    method: "In app",
    message: "Lorem ipsum dolor sit amet text",
  },
];

export { Columns, Rows };
