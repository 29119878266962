/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import CommonContext from "../../hooks/commonContext";
import { Container, Button, TextField, IconButton } from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import ThemeTable from "../../components/Table/ThemeTable";
import { Columns } from "./channelColumns";
import TableToolbar from "../../components/Table/TableToolbar";
import confirm from "../../components/DialogComponent/confirm";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { getFormatedDate } from "../../services/helpers";
import {
  getChannelList,
  updateChannel,
  deleteChannel,
} from "../../services/apiservices/messages";

const ManageChannels = (props) => {
  const commonContext = useContext(CommonContext);
  const history = useNavigate();
  const [allChannels, setAllChannels] = React.useState([]);
  const [searchTerm, setsearchTerm] = React.useState("");
  const fetchChannelList = async () => {
    commonContext?.setLoader(true);
    getChannelList(
      {},
      (res) => {
        if (res) {
          setAllChannels(res);
          commonContext?.setLoader(false);
        } else {
          commonContext?.setLoader(false);
        }
      },
      (resError) => {
        console.log("reserror", resError);
        commonContext?.setLoader(false);
      }
    );
  };
  useEffect(() => {
    fetchChannelList();
  }, []);
  
  const deleteChannelHandler = async(chItem) => {
    await confirm(
      `Are you Sure you want to permanent Remove "${chItem['groupName']}" channel?`
    ).then(() => {
      commonContext?.setLoader(true);
    
    deleteChannel(
      chItem['id'],
      (res) => {
          commonContext?.setLoader(false);
          fetchChannelList();
      },
      (resError) => {
        commonContext?.setLoader(false);
        fetchChannelList();
      }
    );
    }, console.log("cancel!"));
    
  }

  const redirectToCreateChannel = (chId) => {
    if (typeof chId !== "undefined" && chId !== "")
      history("/message-board/create-channel/" + chId);
    else history("/message-board/create-channel");
  };
  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "NoOfMembers":
        return rcellItem['employee'] ? rcellItem['employee'].length: 0;
      case "createdOn":
        return getFormatedDate(rcellItem['createdOn']);
      case "action":
        return (
          <>
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              onClick={() => redirectToCreateChannel(rcellItem?.id)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="Detele"
              className="danger-color"
              size="small"
              onClick={() => deleteChannelHandler(rcellItem)}
            >
              <DeleteIcon />
            </IconButton>
           
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  const tableRightComponent = () => {
    return (
      <div className="d-flex groupsearch">
        <div className=" mh-5">
          <TextField
            fullWidth={true}
            size="small"
            name="searchTerm"
            id="searchTerm"
            label=""
            variant="outlined"
            placeholder="Search Channel..."
            className="input"
            onChange={(event) => {
              setsearchTerm(event.target.value);
            }}
            value={searchTerm ? searchTerm : ""}
          />
        </div>
        <Button
          className="flatbutton w-norm"
          size={"small"}
          variant="contained"
          color="primary"
          onClick={() => redirectToCreateChannel()}
        >
          Add New Channel
        </Button>
      </div>
    );
  };

  const OnSwitchChange = (rowitem, newstatus) => {
    commonContext?.setLoader(true);
    
    updateChannel(
      rowitem?.id,
      { status: newstatus },
      (res) => {
        if (res?.data?.status === true) {
          commonContext?.setLoader(false);
          fetchChannelList();
        } else {
          commonContext?.setLoader(false);
        }
      },
      (resError) => {
        commonContext?.setLoader(false);
        fetchChannelList();
      }
    );
  };
  const checkRowSearch = (searchTerm, row) => {
    return (
      String(row.acuityId)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.groupName)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase())
    );
  };
  const filteredList =
    searchTerm !== ""
      ? allChannels?.filter((item) => checkRowSearch(searchTerm, item))
      : allChannels;
  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Channels"
          showBreadcrumb={[
            { label: "Home", to: "/" },
          ]}
          BreadCrumbCurrentPageTitle={"Manage Channels"}
          showLastSeen={true}
        />

        <div className={`sectionBox`}>
          <TableToolbar
            title="Manage Channels"
            rightComponent={tableRightComponent()}
          />
          <ThemeTable
            rows={filteredList}
            headCells={Columns}
            hidePagination={true}
            renderRowCell={renderRowCell}
            // selectedRows={selectedRows}
            OnSwitchChange={OnSwitchChange}
          />
        </div>
      </Container>
    </>
  );
};
export default ManageChannels;
