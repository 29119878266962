const Columns = [
    // { id: "checkbox", numeric: true, label: "", isSortable: false },
    {
      id: "switch",
      numeric: false,
      disablePadding: false,
      label: "Status",
      isSortable: true,
    },
    {
        id: "clientId",
        numeric: false,
        disablePadding: true,
        label: "Client ID",
        isSortable: true,
    },
    {
        id: "shiftCount",
        numeric: false,
        disablePadding: true,
        label: "Shift Count",
        isSortable: true,
    },
    {
        id: "clientName",
        numeric: false,
        disablePadding: true,
        label: "Client Name",
        isSortable: true,
    },
    {
        id: "primaryContact",
        numeric: false,
        disablePadding: true,
        label: "Primary Contact",
        isSortable: true,
    },
    {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "Email",
        isSortable: true,
    },
    { id: "action", numeric: false, disablePadding: true, label: "Action",rowclassName:"text-right", className:"text-right pr-30" },
];

const Rows = [
    { acuityID: 'PC009485GS', employeename: "William Thomas",contact: '+44 1632 960042', email: "exampleemail7647@gmail.com"},
    { acuityID: 'PC009485GS', employeename: "James lastname",contact: '+44 1632 960042', email: "exampleemail7647@gmail.com"},
    { acuityID: 'PC009485GS', employeename: "Anthony Thomas",contact: '+44 1632 960042', email: "exampleemail7647@gmail.com"},
];

export { Columns, Rows };
  