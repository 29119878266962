import React, { useContext } from "react";
import { TextField, Checkbox } from "@material-ui/core";
import EmployeeStepsContext from "../../../hooks/EmployeeStepsContext";

const StepProfile = (props) => {
  const stepsContext = useContext(EmployeeStepsContext);

  const { handleInputChange } = props;
  const { stepFormValues, stepFormErrors } = stepsContext;

  return (
    <>
      <h5 className="stepSectionTitle">Personal Information</h5>
      <div className="stepSectionContent">
        <div className="formFieldwrap">
          <TextField
            fullWidth={true}
            size="small"
            name="firstName"
            id="firstName"
            label="First Name"
            variant="outlined"
            placeholder="First Name"
            inputProps={{maxLength :20}}
            onChange={handleInputChange}
            value={stepFormValues?.firstName ? stepFormValues?.firstName : ""}
          />
          {typeof stepFormErrors?.firstName !== "undefined" &&
            stepFormErrors?.firstName !== "" && (
              <span className="small error color-danger">
                {stepFormErrors?.firstName}
              </span>
            )}
        </div>
        <div className="formFieldwrap">
          <TextField
            fullWidth={true}
            size="small"
            name="lastName"
            id="lastName"
            label="Last Name"
            variant="outlined"
            placeholder="Last Name"
            inputProps={{maxLength :20}}
            onChange={handleInputChange}
            value={stepFormValues?.lastName ? stepFormValues?.lastName : ""}
          />
          {typeof stepFormErrors?.lastName !== "undefined" &&
            stepFormErrors?.lastName !== "" && (
              <span className="small error color-danger">
                {stepFormErrors?.lastName}
              </span>
            )}
        </div>
        <div className="formFieldwrap w-50">
          <TextField
            fullWidth={true}
            size="small"
            name="phoneNumber"
            id="phoneNumber"
            label="Mobile"
            variant="outlined"
            placeholder="Mobile"
            onChange={handleInputChange}
            value={
              stepFormValues?.phoneNumber ? stepFormValues?.phoneNumber : ""
            }
            // disabled={
            //   typeof stepFormValues?.employeeId !== "undefined" &&
            //   stepFormValues?.employeeId !== ""
            // }
          />
          {typeof stepFormErrors?.phoneNumber !== "undefined" &&
            stepFormErrors?.phoneNumber !== "" && (
              <span className="small error color-danger">
                {stepFormErrors?.phoneNumber}
              </span>
            )}
        </div>
        <div className="formFieldwrap">
          <TextField
            fullWidth={true}
            size="small"
            name="email"
            id="email"
            label="Email"
            variant="outlined"
            placeholder="Email"
            onChange={handleInputChange}
            value={stepFormValues?.email ? stepFormValues?.email : ""}
            // disabled={
            //   typeof stepFormValues?.employeeId !== "undefined" &&
            //   stepFormValues?.employeeId !== ""
            // }
          />
          {typeof stepFormErrors?.email !== "undefined" &&
            stepFormErrors?.email !== "" && (
              <span className="small error color-danger">
                {stepFormErrors?.email}
              </span>
            )}
        </div>
        <div className="formFieldwrap">
        Is Manager? : <Checkbox
            size="small"
            name="isManager"
            id="isManager"
            label="Is Manager?"
            variant="outlined"
            color="primary"
            onChange={handleInputChange}
          
            checked={stepFormValues?.isManager ? stepFormValues?.isManager : false}
            value={stepFormValues?.isManager ? stepFormValues?.isManager : false}
          />
        </div>
      </div>
    </>
  );
};

export default StepProfile;
