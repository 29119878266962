import React, { useEffect, useState } from "react";
import { chagedtime } from "../../services/helpers";
import {alertList} from './AlertsPage';
const AlertsDetails = (props) => {
  const [itemdata, setItemdata] = useState({});

  useEffect(() => {
    setItemdata(props.item);
  }, [props.item]);
  //const emplist = itemdata?.Employee ? itemdata?.Employee?.map((eitem) => eitem.name) : [];
  //const empliststring = emplist.join(', ')
  return (
    <div className="p-15">
      <p>
        <strong>date: </strong> {chagedtime(itemdata?.createdOn)}
      </p>
      <p>
        <strong>Title: </strong> {itemdata?.title}
      </p>
      <p>
        <strong>Type: </strong>{(alertList.find((itm) => itm.value === itemdata?.type))?.label}
      </p>
      <p>
        {/* <strong>client: </strong> {itemdata?.clientname} */}
      </p>
      <p>
        <strong>message: </strong> {itemdata?.description}
      </p>
      <p>
        <strong>Employees: </strong> {itemdata?.empname}
      </p>
      
    </div>
  );
};
export default AlertsDetails;
