/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { registerUser } from "../../services/firebase";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Container,
  FormControlLabel,
  Checkbox,
  CssBaseline,
} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import validator from "validator";

import { Navigate } from "react-router-dom";
// import { register } from "../../services/apiservices/auth";
import { withStyles } from "@material-ui/core/styles";
import AuthContext from "../../hooks/authContext";
import loginBg from "../../assets/images/pc_loginBg.jpg";
import cmsAdminLogo from "../../assets/images/cmsAdminLogo.png";
const styles = (theme) => ({
  pageroot: {
    background: "url(" + loginBg + ") top center no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    height: "100vh",
    paddingTop: theme.spacing(2),
  },
  paper: {},
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
    background: "#078037",
    color: "#fff",
    "&:hover": {
      background: "#078037",
    },
  },
});
const RegisterUser = (props) => {
  const auth = useContext(AuthContext);
  let history = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [state, setState] = useState({
    formState: { username: "", password: "" },
    loading: false,
    sRedirect: "",
    terms: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name !== "") {
      setState({ ...state, formState: { ...state.formState, [name]: value } });
    }
  };
  const redirecifloggedin = () => {
    history("/dashboard");
  };
  useEffect(() => {
    if (auth.status === true) {
      redirecifloggedin();
    }
  }, [auth.status]);

  const onChangeCheckbox = (event) => {
    setState({
      ...state,
      terms: event.target.checked ? event.target.value : false,
    });
  };

  const showErrorMessage = (message) => {
    setErrorMessage(message);
    setState({ ...state, sRedirect: "", loading: false });
  };

  const userRegister = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setState({ ...state, loading: true });
    const { terms } = state;
    const {
      firstname,
      lastname,
      username,
      phonenumber,
      country,
      email,
      password,
      confirmpassword,
    } = state.formState;
    if (email !== "" && password !== "" && confirmpassword !== "") {
      if (terms !== "yes") {
        showErrorMessage("Please Agree with terms");
      } else if (!validator.isEmail(email)) {
        showErrorMessage("Email is not Valid");
      } else if (password !== confirmpassword) {
        showErrorMessage("Password and Confirm Password not match!");
      } else {
        let newuser = await registerUser({
          firstName: firstname,
          lastName: lastname,
          email: email,
          username: username,
          phoneNumber: phonenumber,
          country: country,
          password: password,
          confirmPassword: confirmpassword,
          createdAt: new Date().toISOString(),
          role: "admin",
        });
        if (newuser?.token) {
          auth.login(newuser?.token);
          setState({ ...state, loading: false });
          history("/dashboard");
        } else {
          console.log(newuser);
          showErrorMessage(newuser?.message);
        }

        // await register(
        //   {
        //     firstname: firstname, lastname: lastname, email: email, username: username,
        //     phonenumber: phonenumber, country: country,
        //     password: password, confirmPassword: confirmpassword
        //   },
        //   (res) => {
        //     if (res?.data?.status === true) {
        //       auth.login(res?.data?.token);
        //       setState({ ...state, loading: false });
        //       history('/dashboard');
        //     } else {
        //       showErrorMessage(res?.data?.message);
        //     }
        //   },
        //   (resError) => {
        //     showErrorMessage(resError?.message || "Something Went Wrong!");
        //   }
        // );
      }
    } else {
      showErrorMessage("All Fields above are required!");
    }
    setState({ ...state, loading: false });
  };

  const { formState, loading, sRedirect } = state;
  const { classes } = props;
  return (
    <div className={classes.pageroot}>
      {sRedirect !== "" && <Navigate to={sRedirect} />}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={`${classes.paper} loginwrap sectionBox`}>
          <img className="cmslogo" src={cmsAdminLogo} alt="PROTeam Login" />
          <form className={classes.form} noValidate>
            {/* <div className="formFieldGroup"> */}
              {/*<InputLabel>Name</InputLabel>*/}
              {/* <Input
                fullWidth={true}
                disableUnderline={true}
                onChange={handleInputChange}
                value={formState.username ? formState.username : ''}
                className="input"
                name="username"
                id="username"
                placeholder="Email"
              /> */}
            {/* </div> */}
            <div className="formFieldGroup">
              {/*<InputLabel>Name</InputLabel>*/}
              <Input
                fullWidth={true}
                disableUnderline={true}
                onChange={handleInputChange}
                value={formState.firstname ? formState.firstname : ''}
                className="input"
                name="firstname"
                id="firstname"
                placeholder="First Name"
              />
            </div>
            <div className="formFieldGroup">
              {/*<InputLabel>Name</InputLabel>*/}
              <Input
                fullWidth={true}
                disableUnderline={true}
                onChange={handleInputChange}
                value={formState.lastname ? formState.lastname : ''}
                className="input"
                name="lastname"
                id="lastname"
                placeholder="Last Name"
              />
            </div>
            <div className="formFieldGroup">
              {/*<InputLabel>Username</InputLabel>*/}
              <Input
                fullWidth={true}
                disableUnderline={true}
                onChange={handleInputChange}
                value={formState.phonenumber ? formState.phonenumber : ''}
                className="input"
                name="phonenumber"
                id="phonenumber"
                placeholder="phonenumber"
              />
            </div>
            <div className="formFieldGroup">
              {/*<InputLabel>Username</InputLabel>*/}
              <Input
                fullWidth={true}
                disableUnderline={true}
                onChange={handleInputChange}
                value={formState.country ? formState.country :''}
                className="input"
                name="country"
                id="country"
                placeholder="country"
              />
            </div>

            <div className="formFieldGroup">
              {/*<InputLabel>Username</InputLabel>*/}
              <Input
                fullWidth={true}
                disableUnderline={true}
                onChange={handleInputChange}
                value={formState.email ? formState.email : ''}
                autoComplete="email"
                className="input"
                name="email"
                id="email"
                placeholder="Email"
              />
            </div>
            <div className="formFieldGroup">
              {/*<InputLabel>Password</InputLabel>*/}
              <Input
                fullWidth={true}
                disableUnderline={true}
                onChange={handleInputChange}
                value={formState.password ? formState.password :''}
                className="input"
                name="password"
                id="password"
                type="password"
                placeholder="Password"
                autoComplete="new-password"
              />
            </div>
            <div className="formFieldGroup">
              {/*<InputLabel>Confirm Password</InputLabel>*/}
              <Input
                fullWidth={true}
                disableUnderline={true}
                onChange={handleInputChange}
                value={formState.confirmpassword ? formState.confirmpassword : ''}
                className="input"
                name="confirmpassword"
                id="confirmpassword"
                autoComplete="new-password-confirm"
                placeholder="Confirm Password"
                type="password"
              />
            </div>

            <div className="loginbottom">
              <FormControlLabel
                control={
                  <Checkbox
                    value="yes"
                    color="primary"
                    checked={Boolean(state?.terms ? state?.terms : false)}
                    onChange={onChangeCheckbox}
                  />
                }
                label="I Agree to all Terms"
              />
            </div>
            <Button
              type="submit"
              fullWidth={true}
              className={`${classes.submit}`}
              color={"primary"}
              variant={"contained"}
              onClick={userRegister}
              disabled={loading ? true : false}
            >
              {loading ? "Loading..." : "SIGN UP"}
            </Button>
            <Typography className="text-center" variant="body2">
              Already have an account? <a href="/login"> Sign In</a>
            </Typography>
            {errorMessage !== "" && (
              <Typography className="text-center" color="error" variant="body2">
                {errorMessage}
              </Typography>
            )}
          </form>
        </div>
      </Container>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(RegisterUser);
