import { firebase } from '../firebase-config';
import { BaseUrl, COLLECTIONS } from '../config';
import axiosInstance from '../axios';
import { getLoginToken } from '../storage';
const collection = COLLECTIONS.ALERT;
export async function getalertsList(onSuccess, onError) {
  firebase
    .firestore()
    .collection(collection)
    .orderBy('createdOn', 'desc')
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });

      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
      onError && onError(error);
    });
}

export async function getalertsListByParam(param, onSuccess, onError) {
  firebase
    .firestore()
    .collection(collection)
    .orderBy('createdOn', 'desc')
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });

      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
      onError && onError(error);
    });
}
