import React, { useState } from 'react';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemText,
    Button,
    Typography,
    CardActions
} from '@material-ui/core';
import { useMediaQuery } from '@mui/material';
import styles from "../Reports.module.scss";
import ThemeTable from '../../Table/ThemeTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFilePdf, faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';

const ResponsiveTable = ({
    rows,
    getColumns,
    isLoading,
    renderRowCell,
    extraHeader,
    detailedView,
    setDetailedView,
    fileName,
    onClickPdf,
    onClickExcel,
    onClickCsv,
    clientsListCSV,
    onClickEmail,
    task,
    setTask,
    taskDetails,
    setTaskDetails
}) => {
    const isMobile = useMediaQuery('(max-width:768px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

    const handleViewDetails = (ClientRecords) => {
        setDetailedView(ClientRecords);
    };

    const groupedByClient = rows.reduce((acc, row) => {
        const clientName = row.clientName || row.employeeName;
        if (!acc[clientName]) {
            acc[clientName] = {};
        }
        const dateKey = row.appointmentDate;
        if (!acc[clientName][dateKey]) {
            acc[clientName][dateKey] = [];
        }
        acc[clientName][dateKey].push(row);
        return acc;
    }, {});

    const flattenGroupedByClient = Object.keys(groupedByClient).reduce((acc, clientName) => {
        acc[clientName] = [];
        Object.keys(groupedByClient[clientName]).forEach(dateKey => {
            acc[clientName] = acc[clientName].concat(groupedByClient[clientName][dateKey]);
        });
        return acc;
    }, {});


    return (
        <>
            {detailedView ? (
                task != true ?
                    <DetailedViewPage
                        ClientRecords={detailedView}
                        getColumns={getColumns}
                        isLoading={isLoading}
                        renderRowCell={renderRowCell}
                        isMobile={isMobile}
                        isLandscape={isLandscape}
                        extraHeader={extraHeader}
                        setDetailedView={setDetailedView}
                        fileName={fileName}
                    />
                    :
                    <DetailedView2Page
                        ClientRecords={detailedView}
                        setDetailedView={setDetailedView}
                        task={task}
                        setTask={setTask}
                        taskDetails={taskDetails}
                        setTaskDetails={setTaskDetails} />
            ) : (
                !isMobile && !isLandscape ? (
                    <ThemeTable
                        rows={rows}
                        headCells={getColumns()}
                        hidePagination={true}
                        isLoading={isLoading}
                        renderRowCell={renderRowCell}
                        extraHeader={extraHeader}
                    />
                ) : (
                    Object.keys(flattenGroupedByClient).map((clientName) => (
                        <Card key={clientName} className={` ${styles.card}`}>
                            <CardHeader title={clientName} className={` ${styles.cardHeader}`} />
                            <CardContent className={` ${styles.cardContent}`}>
                                {Object.keys(groupedByClient[clientName]).map((dateKey) => (
                                    <React.Fragment key={`${clientName}-${dateKey}`}>
                                        <List>
                                            <ListItem className={` ${styles.listItem}`}>
                                                <ListItemText primary={dateKey} />
                                                <KeyboardArrowRightSharpIcon onClick={() => handleViewDetails(groupedByClient[clientName][dateKey])} />
                                            </ListItem>
                                            <Divider className={` ${styles.divider}`}/>
                                        </List>
                                    </React.Fragment>
                                ))}
                            </CardContent>
                            <CardActions style={{ justifyContent: "space-between" }} className={` ${styles.listItemm}`}>
                                <Typography variant="body2">Actions</Typography>
                                <div className={`${styles.icons}`}>
                                    <FontAwesomeIcon icon={faEnvelope} size='lg' onClick={() => onClickEmail(flattenGroupedByClient[clientName])} />
                                    <FontAwesomeIcon icon={faFilePdf} size='lg' onClick={() => onClickPdf(flattenGroupedByClient[clientName])} />
                                    <CSVLink
                                        data={clientsListCSV}
                                        filename={`${fileName}_${clientName}.csv`}
                                        asyncOnClick={true}
                                        onClick={(event, done) => onClickCsv(event, done, flattenGroupedByClient[clientName])}
                                    >
                                        <FontAwesomeIcon icon={faFileCsv} size='lg' className='color-primary' />
                                    </CSVLink>
                                    <FontAwesomeIcon icon={faFileExcel} size='lg' onClick={() => onClickExcel(flattenGroupedByClient[clientName])} />
                                </div>
                            </CardActions>
                        </Card>
                    ))
                )
            )}
        </>
    );
};


const shouldShowListItem = (currentIndex, currentRecord, previousRecord, fileName) => {
    if (currentIndex === 0) {
        return true;
    }
    if (fileName === "Work Site Report" && currentRecord.employeeName !== previousRecord.employeeName) {
        return true;
    }
    return false;
};

const DetailedViewPage = ({ ClientRecords, getColumns, isLoading, renderRowCell, extraHeader, isMobile, isLandscape, setDetailedView, fileName }) => {
    return (
        <>
            <Card className={` ${styles.card2}`}>
                <CardHeader
                    title={ClientRecords[0]?.clientName || ClientRecords[0]?.employeeName}
                    className={styles.cardHeader}
                    // action={
                    //     <Button
                    //         className={styles.button}
                    //         size={"small"}
                    //         variant="contained"
                    //         color='primary'
                    //         onClick={() => setDetailedView(null)}>
                    //         BACK
                    //     </Button>
                    // }
                />
                {fileName !== "Work Site Report" && (
                    <ListItem className={styles.listItemm2}>
                        <ListItemText
                            primary={ClientRecords[0]?.appointmentDate}
                            className={`${styles.listText}`}
                        />
                    </ListItem>
                )}
                <CardContent className='MuiCardContent-root'>
                    {fileName === "Work Site Report" ? (
                        <>
                            {ClientRecords.map((record, index) => (
                                <React.Fragment key={`record-${index}`}>
                                    {shouldShowListItem(index, record, ClientRecords[index - 1], fileName) && (
                                        <ListItem className={styles.listItemm2} key={`appointment-${index}`}>
                                            <ListItemText
                                                primary={record.appointmentDate}
                                                className={`${styles.listText}`}
                                            />
                                        </ListItem>
                                    )}
                                    <ThemeTable
                                        rows={[record]}
                                        headCells={getColumns()}
                                        hidePagination={true}
                                        isLoading={isLoading}
                                        noBorder={true}
                                        divider={true}
                                        renderRowCell={(rcellItem, hcellItem, rowIndex) =>
                                            renderRowCell(rcellItem, hcellItem, 0, rowIndex, ClientRecords, fileName, isMobile, isLandscape)
                                        }
                                        extraHeader={!isMobile && !isLandscape ? extraHeader : false}
                                    />
                                </React.Fragment>
                            ))}
                        </>
                    ) :
                        <ThemeTable
                            rows={ClientRecords}
                            headCells={getColumns()}
                            hidePagination={true}
                            isLoading={isLoading}
                            noBorder={true}
                            divider={true}
                            renderRowCell={(rcellItem, hcellItem, index, rowindex) =>
                                renderRowCell(rcellItem, hcellItem, index, rowindex, isMobile, isLandscape)
                            }
                            extraHeader={!isMobile && !isLandscape ? extraHeader : false}
                        />

                    }
                </CardContent>
            </Card>
            <Button
                className={styles.button2}
                size={"small"}
                variant="contained"
                color='primary'
                onClick={() => setDetailedView(null)}>
                BACK
            </Button>
        </>
    );
};


const DetailedView2Page = ({ ClientRecords, setTask, taskDetails, setTaskDetails }) => {
    return (
        <>
            <Card className={` ${styles.card2}`}>
                <CardHeader
                    title={ClientRecords[0]?.clientName}
                    className={styles.cardHeader}
                    // action={
                    //     <Button
                    //         className={styles.button}
                    //         size="small"
                    //         variant="contained"
                    //         color="primary"
                    //         onClick={() => { setTask(false); setTaskDetails(null); }}
                    //     >
                    //         BACK
                    //     </Button>
                    // }
                />
                <ListItem className={styles.listItemm2}>
                    <ListItemText
                        primary={ClientRecords[0]?.appointmentDate}
                        className={`${styles.listText}`}
                    />
                </ListItem>
                <CardContent className={styles.cardContent}>
                    <table className={styles.taskTable}>
                        <thead>
                            <tr>
                                <th>Tasks</th>
                                <th>Task Description</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {taskDetails.map((task, index) => (
                                <tr key={index}>
                                    <td>{task.task}</td>
                                    <td className={styles.description}>{task.taskDescription === "---" ? "-" : task.taskDescription}</td>
                                    <td>{task.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </CardContent>
            </Card>
            <Button
                className={styles.button2}
                size="small"
                variant="contained"
                color="primary"
                onClick={() => { setTask(false); setTaskDetails(null); }}
            >
                BACK
            </Button>
        </>
    );
};
export default ResponsiveTable;
