import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Container,
  CssBaseline,
} from "@material-ui/core";
import Input from '@material-ui/core/Input';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Navigate } from "react-router-dom";
// import { sendrecoveremail } from "../../services/apiservices/auth";
import { forgotPasswordUser } from "../../services/firebase";
import { withStyles } from "@material-ui/core/styles";
import AuthContext from "../../hooks/authContext";
import loginBg from "../../assets/images/pc_loginBg.jpg";
import cmsAdminLogo from "../../assets/images/cmsAdminLogo.png";
const styles = (theme) => ({
  pageroot: {
    background: 'url(' + loginBg + ') top center no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    height: '100vh',
    paddingTop: theme.spacing(2),
  },
  paper: {
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
    background: '#078037',
    color: '#fff',
    '&:hover': {
      background: '#078037'
    }
  },
});
const ForgotPassword = (props) => {
  let history = useNavigate();
  const auth = useContext(AuthContext);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [state, setState] = useState({
    formState: { email: "", password: "" },
    loading: false,
    sRedirect: "",
    terms: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name !== "") {
      setState({ ...state, formState: { ...state.formState, [name]: value } });
    }
  };
  const redirecifloggedin = () => {
    history('/dashboard');
  }

  useEffect(() => {
    if (auth.status === true) {
      redirecifloggedin();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.status]);

  const showErrorMessage = (message) => {
    setErrorMessage(message);
    setState({ ...state, sRedirect: "", loading: false });
  }

  const SendRecoveryLink = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setState({ ...state, loading: true });

    const { email } = state.formState;
    if (email !== "") {
      if (!validator.isEmail(email)) {
        showErrorMessage('Email is not Valid');
      } else {
        
        await forgotPasswordUser(email,  () => {
          
            setState({ ...state, loading: false });
            
            setSuccessMessage("Reset Password Link sent to your Email, Redirecting to Login...");
            setTimeout(()=>{
              history('/login');
            },4000);
        },
        (resError) => {
          setState({ ...state, loading: false });
          showErrorMessage(resError?.message || "Something Went Wrong!");
        })
      }
    } else {
      showErrorMessage("Email Fields is required!");
    }
    setState({ ...state, loading: false });
  };

  const { formState, loading, sRedirect } = state;
  const { classes } = props;
  return (
    <div className={classes.pageroot}>
      {sRedirect !== "" && <Navigate to={sRedirect} />}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={`${classes.paper} loginwrap sectionBox`}>
          <img className="cmslogo" src={cmsAdminLogo} alt="PROTeam Login" />
          <form className={classes.form} noValidate>
            <div className="formFieldGroup">
              {/*<InputLabel>Username</InputLabel>*/}
              <Input
                fullWidth={true}
                disableUnderline={true}
                onChange={handleInputChange}
                value={formState.email}
                autoComplete="email"
                className="input"
                name="email"
                id="email"
                placeholder="Email"
                startAdornment={<span className="input-adornment"><FontAwesomeIcon icon={faUser} /></span>}
              />
            </div>
            <Button
              type="submit"
              fullWidth={true}
              className={`${classes.submit}`}
              color={"primary"}
              variant={"contained"}
              onClick={SendRecoveryLink}
              disabled={loading ? true : false}
            >
              {loading ? "Loading..." : "RESET PASSWORD"}
            </Button>
            <Typography className="text-center mt-25" variant="body2">
              Already Registred ? <a className="color-blue" href="/login"> Click here</a>
            </Typography>
            {errorMessage !== "" && (
              <Typography className="text-center" color="error" variant="body2">
                {errorMessage}
              </Typography>
            )}
            {successMessage !== "" && (
              <Typography className="text-center" color="primary" variant="body2">
                {successMessage}
              </Typography>
            )}
          </form>
        </div>
      </Container>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ForgotPassword);
