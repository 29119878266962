/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import CommonContext from "../../hooks/commonContext";
import { Container, Button, TextField, IconButton } from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import ThemeTable from "../../components/Table/ThemeTable";
import { Columns } from "./employeeTableColumns";
import TableToolbar from "../../components/Table/TableToolbar";
// import DialogComponent from "../../components/DialogComponent/DialogComponent";

// import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import { useEffect } from "react";
import {
  getEmployeesList,
  updateEmployee,
} from "../../services/apiservices/employee";

const Employee = (props) => {
  const commonContext = useContext(CommonContext);
  const history = useNavigate();
  const [allEmployees, setAllEmployees] = React.useState([]);
  const [searchTerm, setsearchTerm] = React.useState("");
  const fetchEmployeesList = async () => {
    commonContext?.setLoader(true);
    getEmployeesList(
      {},
      (res) => {
        if (res) {
          let allEmp = res?.map((eitem) => {
            return {
              ...eitem,
              userName: `${eitem?.firstName} ${eitem?.lastName}`,
            };
          });
          setAllEmployees(allEmp);
          commonContext?.setLoader(false);
        } else {
          commonContext?.setLoader(false);
        }
      },
      (resError) => {
        console.log("reserror", resError);
        commonContext?.setLoader(false);
      }
    );
  };
  useEffect(() => {
    fetchEmployeesList();
  }, []);
// console.log("alldata",allEmployees);
  const redirectToCreateEmp = (employeeId) => {
    if (typeof employeeId !== "undefined" && employeeId !== "")
      history("/createEmp/" + employeeId);
    else history("/createEmp");
  };

  const loginWithEmployee = (empDetails) =>{
    console.log(empDetails);
      history("/ViewEmployeeApp/" + empDetails?.id);
    
  }

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "action":
        return (
          <>
          {rcellItem?.role === 'Employee'? 
          <IconButton
              aria-label="Login"
              color="primary"
              size="small"
              onClick={() => loginWithEmployee(rcellItem)}
            >
              <LockIcon />
            </IconButton>:null}
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              onClick={() => redirectToCreateEmp(rcellItem?.id)}
            >
              <EditIcon />
            </IconButton>
            {/* <IconButton aria-label="View" color="primary">
              <VisibilityIcon />
            </IconButton>
            <IconButton aria-label="Edit" color="primary">
              <EditIcon />
            </IconButton>

            <DialogComponent
              buttonWrapClassName={"d-inline"}
              buttonClassName={"iconButton color-danger"}
              buttonProps={{ size: "small" }}
              buttonLabel={<DeleteOutlineIcon />}
              ModalTitle={"Remove ?"}
              ModalContent={<div>Remove</div>}
            /> */}
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  const tableRightComponent = () => {
    return (
      <div className="d-flex groupsearch">
        <div className=" mh-3">
          <TextField
            fullWidth={true}
            size="small"
            name="searchTerm"
            id="searchTerm"
            label=""
            variant="outlined"
            placeholder="Search Employee..."
            className="input"
            onChange={(event) => {
              setsearchTerm(event.target.value);
            }}
            value={searchTerm ? searchTerm : ""}
          />
        </div>
        <Button
          className="flatbutton w-norm"
          size={"small"}
          variant="contained"
          color="primary"
          onClick={() => redirectToCreateEmp()}
        >
          Add New Employee
        </Button>
      </div>
    );
  };

  const OnSwitchChange = (rowitem, newstatus) => {
    commonContext?.setLoader(true);
    updateEmployee(
      rowitem?.id,
      { status: newstatus },
      (res) => {
        if (res?.data?.status === true) {
          commonContext?.setLoader(false);
          fetchEmployeesList();
        } else {
          commonContext?.setLoader(false);
        }
      },
      (resError) => {
        commonContext?.setLoader(false);
        fetchEmployeesList();
      }
    );
  };
  const checkRowSearch = (searchTerm, row) => {
    return (
      String(row.acuityId)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.email)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.userName)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.phoneNumber)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase())||
        String(row.employeerole)
          .toLowerCase()
          .includes(String(searchTerm).toLowerCase())
    );
  };
  const filteredList =
    searchTerm !== ""
      ? allEmployees?.filter((item) => checkRowSearch(searchTerm, item))
      : allEmployees;
  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Employee"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Employee", to: "/" },
          ]}
          BreadCrumbCurrentPageTitle={"Manage Employee"}
          showLastSeen={true}
        />

        <div className={`sectionBox`}>
          <TableToolbar
            title="Employee"
            rightComponent={tableRightComponent()}
          />
          <ThemeTable
            rows={filteredList}
            headCells={Columns}
            hidePagination={true}
            renderRowCell={renderRowCell}
            // selectedRows={selectedRows}
            OnSwitchChange={OnSwitchChange}
          />
        </div>
      </Container>
    </>
  );
};
export default Employee;
