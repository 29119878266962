import { firebase } from "../firebase-config";
import { BaseUrl, COLLECTIONS } from "../config";
import axiosInstance from "../axios";
import { getLoginToken } from "../storage";
import moment from 'moment';

const msg_collection = COLLECTIONS.CHAT_CHANNELS;
const chat_collection = COLLECTIONS.CHAT_MESSAGES;

/**
 * Get Tasks list from standardtasks collection (shown while create client)
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getChannelList(param, onSuccess, onError) {

  const query = firebase
    .firestore()
    .collection(msg_collection).orderBy("createdOn", "desc");

  return query.onSnapshot(querySnapshot => {
    // console.log(`Received query snapshot of size ${querySnapshot.size}`);
    let results = [];
    querySnapshot.forEach((doc) => {
      // console.log(doc.id);
      let data = doc.data();
      results.push({ id: doc.id, ...data });
    });
    onSuccess && onSuccess(results);
    // ...
  }, err => {
    onError && onError(err);
    console.log(`Encountered error: ${err}`);
  });
  // firebase
  //   .firestore()
  //   .collection(msg_collection)
  //   .orderBy("createdOn", "desc")
  //   .get()
  //   .then((querySnapshot) => {
  //     let results = [];
  //     querySnapshot.forEach((doc) => {
  //       let data = doc.data();
  //       results.push({ id: doc.id, ...data });
  //     });

  //     onSuccess && onSuccess(results);
  //   })
  //   .catch((error) => {
  //     console.log("Error getting documents: ", error);
  //     onError && onError(error);
  //   });
}
/**
 * send channel and save it to database
 * @param {*} formData : data to be saved and sent as channels
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function saveChannel(formData, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  firebase
    .firestore()
    .collection(msg_collection)
    .add({
      ...formData,
      createdBy: currentUserId,
      createdOn: new Date().getTime(),
    })
    .then(
      async (doc) => {
        const docdata = formData;
        docdata.id = doc.id;
        onSuccess && onSuccess({ status: true, data: docdata });
      },
      (error) => {
        console.log(`ERROR: ${msg_collection} ${error}`);
        onError({ status: false, error: error });
      }
    )
    .catch((e) => {
      console.log(`ERROR: ${msg_collection} ${e}`);
      onError({ status: false, error: e });
    });
}

/**
 * send channel and save it to database
 * @param {*} formData : data to be saved and sent as update channel
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function updateChannel(id, formData, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  if (formData.id) {
    delete formData.id;
  }
  let document = await firebase.firestore().collection(msg_collection).doc(id);
  document
    .set(
      {
        ...formData,
        updatedAt: new Date().getTime(),
        updatedBy: currentUserId,
      },
      { merge: true }
    )
    .then(
      async (doc) => {
        const docdata = formData;
        docdata.id = id;

        onSuccess && onSuccess({ status: true, data: docdata });
      },
      (error) => {
        console.log(`ERROR: ${msg_collection} ${error}`);
        onError({ status: false, error: error });
      }
    )
    .catch((e) => {
      console.log(`ERROR: ${msg_collection} ${e}`);
      onError({ status: false, error: e });
    });
}
/**
 * Permanantly Remove Issue from database
 * @param {*} id
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function deleteChannel(id, onSuccess, onError) {
  const document = firebase.firestore().collection(msg_collection).doc(id);
  document
    .delete()
    .then(() => {
      onSuccess && onSuccess({ status: true, message: "Delete successfully" });
    })
    .catch((error) => {
      onError && onError({ status: false, error: error });
    });
}

/**
 * Get details of particular Channel
 * @param {*} id : id of Channel
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getChannel(id, onSuccess, onError) {
  try {
    firebase
      .firestore()
      .collection(msg_collection)
      .doc(id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          onError && onError({ status: false, error: "Channel not found" });
        } else {
          let data = doc.data();
          onSuccess &&
            onSuccess({ status: true, data: { id: doc.id, ...data } });
        }
      })
      .catch((error) => {
        onError && onError({ status: false, error: error });
      });
  } catch (res_1) {
    onError && onError(res_1);
  }
}

/**
 * Get details of send message to channel
 * @param {*} formData : Message Data {channelId, sender:{id, firstName, lastName}, type:{text/image}, readBy:[], message, image}
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function sendMessageToChannel(formData, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  try {
    firebase
      .firestore()
      .collection(chat_collection)
      .add({
        ...formData,
        createdOn: new Date().getTime(),
        updatedOn: new Date().getTime(),
      })
      .then(
        async (doc) => {
          const docdata = formData;
          docdata.id = doc.id;
          onSuccess && onSuccess({ status: true, data: docdata });
        },
        (error) => {
          console.log(`ERROR: ${chat_collection} ${error}`);
          onError({ status: false, error: error });
        }
      )
      .catch((e) => {
        console.log(`ERROR: ${chat_collection} ${e}`);
        onError({ status: false, error: e });
      });
  } catch (res_1) {
    onError && onError(res_1);
  }
}

/**
 * Get  particular Channel Message
 * @param {*} channelId : id of Channel
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getChannelMessage(channelId, onSuccess, onError) {
  try {
    firebase
      .firestore()
      .collection(chat_collection)
      .where("channelId", "==", channelId)
      .orderBy("updatedOn")
      .get()
      .then((querySnapshot) => {
        let results = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          results.push({ id: doc.id, ...data });
        });

        onSuccess && onSuccess(results);
      })
      .catch((error) => {
        onError && onError({ status: false, error: error });
      });
  } catch (res_1) {
    onError && onError(res_1);
  }
}

export function getMessages(channelId, onSuccess, onError) {

  getChannel(channelId, (channel) => {
    const channelData = channel.data;
    let currentUserId = firebase.auth().currentUser.uid;
    if (channelData.readMsgUsers) {
      if (!channelData.readMsgUsers.includes(currentUserId)) {
        channelData.readMsgUsers.push(currentUserId);
        updateChannel(channelId, channelData);
      }
    } else {
      channelData.readMsgUsers = [currentUserId];
      updateChannel(channelId, channelData);
    }
  });
  const query = firebase
    .firestore()
    .collection(chat_collection)
    .where("channelId", "==", channelId)
    .orderBy("updatedOn");

  return query.onSnapshot(querySnapshot => {
    let results = [];
    let imagesObject = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      let isNeedtoAdd = true;
      if (data.type === 'image') {
        if (imagesObject.length > 0) {
          if (data.sender.userId == imagesObject[0].sender.userId) {
            let duration = moment.duration(moment(data.createdOn).diff(moment(imagesObject[0].createdOn)));
            let diffMins = duration.asMinutes();
            // console.log(diffMins, doc.id, { ...data, id: doc.id });
            if (diffMins <= 1 && data.message == "") {
              imagesObject.push({ id: doc.id, ...data });
              isNeedtoAdd = false;
            } else if (diffMins <= 1 && data.message != "") {
              imagesObject.push({ id: doc.id, ...data });
              isNeedtoAdd = true;
            } else {
              isNeedtoAdd = true;
            }
          }
        } else {
          // console.log(doc.id, { ...data, id: doc.id });
          imagesObject.push({ id: doc.id, ...data });
          isNeedtoAdd = false;
        }
      }
      if (isNeedtoAdd) {
        if (imagesObject.length > 0) {
          if (doc.id === imagesObject[imagesObject.length - 1].id) {
            results.push({ ...imagesObject[imagesObject.length - 1], imagesObj: imagesObject });
            imagesObject = [];
          } else {
            results.push({ ...imagesObject[imagesObject.length - 1], imagesObj: imagesObject });
            if (data.type === 'image') {
              imagesObject = [];
              imagesObject.push({ id: doc.id, ...data })
            } else {
              results.push({ id: doc.id, ...data });
              imagesObject = [];
            }
          }
        } else {
          results.push({ id: doc.id, ...data });
          imagesObject = [];
        }

      } else {
        const lastDoc = querySnapshot.docs[querySnapshot.size - 1];
        if (lastDoc.id == doc.id) {
          if (imagesObject?.length > 0) {
            results.push({ ...imagesObject[imagesObject.length - 1], imagesObj: imagesObject });
            imagesObject = [];
          }
        }
      }

    });
    // console.log(results);
    onSuccess && onSuccess(results);
    // ...
  }, err => {
    onError && onError(err);
    console.log(`Encountered error: ${err}`);
  });


}
