/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import moment from "moment";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import CommonContext from "../../../../hooks/commonContext";
import {
  Container,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  TextField,
} from "@material-ui/core";
import PageTitle from "../../../../components/pageTitle/PageTitle";
import ThemeTable from "../../../../components/Table/ThemeTable";
import { Columns } from "./suppliesRequestTableColumns";
import TableToolbar from "../../../../components/Table/TableToolbar";
import { useEffect } from "react";
import {
  getSupplyOrderListBasedOnId,
  supplyRejectReasons,
  // saveRejectionReson,
  updateSuppliesStatus,
} from "../../../../services/apiservices/supplies";
import { getFormatedDate } from "../../../../services/helpers";
import { getClientsListbasedonIds } from "../../../../services/apiservices/client";
import ConfirmationDialog from "../../../../components/DialogComponent/ConfirmationDialog";
import SingleSelect from "../../../../components/SelectField/SingleSelect";

const SuppliesRequestDetails = (props) => {

  const commonContext = useContext(CommonContext);
  let history = useNavigate();

  const { requestId } = useParams();
  const [requestData, setRequestData] = React.useState({});
  const [allSupplies, setAllSupplies] = React.useState([]);
  const [selectedReason, setSelectedReason] = useState('');
  const [reasonMessage, setReasonMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  const OnSuccessGetSupplies = async (res) => {
    let clientIds = [res.clientId];
    let clintsData = [];
    await getClientsListbasedonIds(clientIds, (result) => {
      const clients = result.map((item) => {
        return { value: item?.id, label: item?.clientName };
      });
      clintsData = clients;
    });
    const client = clintsData.find(client => client?.value === res?.clientId);
    const clientName = client ? client.label : 'Unknown Client';

    let totalQuantity = 0;

    if (res?.supplyItems) {
      res.supplyItems.forEach((item) => {
        totalQuantity += item.qty || 0;
      });
    }

    let priorityLabel;
    switch (res.priority) {
      case "1":
        priorityLabel = "High";
        break;
      case "2":
        priorityLabel = "Medium";
        break;
      case "3":
        priorityLabel = "Low";
        break;
      default:
        priorityLabel = "Unknown";
    }
    let hasMaxNumberAllowed = res.supplyItems.some(item => item.maxNumberAllowed !== undefined);
    const finaleData = {
      ...res,
      orderId: String(res.orderNumber).padStart(6, '0'),
      clientName: clientName,
      priority: priorityLabel,
      itemName: hasMaxNumberAllowed ? 'Clothing' : 'Supplies',
      dateOfRequest: res?.dateOfRequest,
    };

    setAllSupplies(finaleData);
  };
  const getRequestData = (requestId) => {
    if (typeof requestId !== "undefined" && requestId !== "") {
      commonContext?.setLoader(true);
      getSupplyOrderListBasedOnId(
        requestId,
        async (res) => {
          OnSuccessGetSupplies(res);
          commonContext?.setLoader(false);
        },
        (resError) => {
          commonContext?.setLoader(false);
        }
      );
    } else {
      setRequestData({});
      commonContext?.setLoader(false);
    }
  };
  useEffect(() => {
    getRequestData(requestId);
  }, [requestId]);

  const tableLeftComponent = () => {
    return (
      <Typography className={`tableTitle w-100 strong`} variant="body1">
        {`Supplies Request #${allSupplies.orderId} - ${allSupplies?.clientName ? allSupplies?.clientName : ""
          }`}
      </Typography>
    );
  };

  const tableRightComponent = () => {
    return (
      <Typography className={`tableTitle strong`} variant="body1">
        {`Date of Request ${allSupplies?.dateOfRequest
          ? moment(allSupplies?.dateOfRequest).format("MMMM D, YYYY")
          : ""
          }`}
      </Typography>
      //   <div className="d-flex flex-center w-100"></div>
    );
  };

  const handleChange = (e, rcellItem) => {
    const selectedValue = e.target.value;
    setSelectedStatus(selectedValue);
  };

  const handleDialogOpen = (item) => {
    setSelectedItem(item);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedItem(null);
    setSelectedReason('');
    setReasonMessage('');
  };

  const handleProceed = async () => {
    const result = {
      reason: selectedReason,
      message: reasonMessage,
    };

    try {
      await supplyRejectReasons(selectedItem.id, result);
      await handelRejectedClick(selectedItem.id);
      handleDialogClose();
    } catch (error) {
      console.error("Error handling proceed:", error);
    }
  };

  const handelApproveClick = async (id) => {
    const formData = {
      status: "Approved",
    };
    try {
      await updateSuppliesStatus(id, formData);
      history("/supplies/cleaning/review");
    } catch (error) {
      console.error("Error handling approve:", error);
    }
  };

  const handelRejectedClick = async (id) => {
    const formData = {
      status: "Rejected",
    };
    try {
      await updateSuppliesStatus(id, formData);
      history("/supplies/cleaning/review");
    } catch (error) {
      console.error("Error handling reject:", error);
    }
  };


  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "dateOfRequest":
        return getFormatedDate(rcellItem?.dateOfRequest);
        break;
      case "action":
        return (
          <>
           <FormControl component="fieldset">
              <RadioGroup
                aria-label="status"
                name="requeststatus"
                value={selectedStatus}
                onChange={(e) => handleChange(e, rcellItem)}
                className="d-flex flex-direction-row"
              >
                <FormControlLabel
                  value="Approved"
                  control={<Radio size="small" color="primary" />}
                  label="Approved"
                  onClick={() => handelApproveClick(rcellItem.id)}
                  style={{ color: selectedStatus === "Approved" ? "#2980ba" : "inherit" }}
                />
                <FormControlLabel
                  value="Rejected"
                  control={<Radio size="small" color="primary" />}
                  label="Rejected"
                  onClick={() => handleDialogOpen(rcellItem)}
                  style={{ color: selectedStatus === "Rejected" ? "#2980ba" : "inherit" }}
                />
              </RadioGroup>
            </FormControl>

            {dialogOpen && selectedItem && selectedItem.id === rcellItem.id && (
              <ConfirmationDialog
                show={dialogOpen}
                title="Reason to Reject Supply"
                confirmation={
                  <>
                    <SingleSelect
                      select
                      label="Reason"
                      fullWidth
                      margin="normal"
                      value={selectedReason}
                      onChange={(e) => setSelectedReason(e.target.value)}
                      options={[
                        { label: "Recent Shipment", value: "Recent Shipment" },
                        { label: "Duplicate Request", value: "Duplicate Request" },
                        { label: "Contact Management", value: "Contact Management" }
                      ]}
                    />
                    <TextField
                      fullWidth
                      multiline={true}
                      label="Reason Message"
                      variant="outlined"
                      placeholder="Reason Message"
                      rows={3}
                      margin="normal"
                      value={reasonMessage}
                      onChange={(e) => setReasonMessage(e.target.value)}
                    />
                  </>
                }
                proceed={handleProceed}
                dismiss={handleDialogClose}
                cancel={handleDialogClose}
              />
            )}
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Supplies Request"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Supplies", to: "/supplies/cleaning" },
            // { label: "Supplies Request", to: "/" },
          ]}
        // showLastSeen={true}
        />

        <div className={`sectionBox`}>
          <TableToolbar
            title=""
            rightComponent={tableRightComponent()}
            leftComponent={tableLeftComponent()}
          />
          <ThemeTable
            rows={[allSupplies]}
            headCells={Columns}
            hidePagination={true}
            renderRowCell={renderRowCell}
          // selectedRows={selectedRows}
          />
        </div>
      </Container>
    </>
  );
};
export default SuppliesRequestDetails;
