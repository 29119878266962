/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Button, Typography } from "@material-ui/core";
import ConfirmCancel from "../../../components/DialogComponent/ConfirmCancel";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../../components/AccordionComponent/AccordionComponent";
import DialogComponent from "../../../components/DialogComponent/DialogComponent";
import CreateTaskItem from "./CreateTaskItem";

import { getStandardTasksList } from "../../../services/apiservices/tasks";
import TaskSummary from "./TaskSummary";
import { EventEmitter } from "../../../services/event.service";
import { useParams } from "react-router";
import { deleteClientTaskGroup } from "../../../services/apiservices/client";
const stepId = 4;
function Step(props) {
  const createTaskRoot = useRef(null);
  const [stepError, setStepError] = useState({});
  const [taskList, settaskList] = useState([]);
  const [taskFrequencies, setTaskFrequencies] = useState([]);
  const [customTaskList, setCustomTaskList] = useState([]);
  const [SummaryList, setSummaryList] = useState([]);
  const { clientId } = useParams();

  const {
    currentStep,
    goToStep,
    onCancelAll,

    loading,
    classes,
  } = props;

  const fetchsettaskList = () => {
    setTaskFrequencies([
      "Daily",
      "Weekends",
      "Weekly",
      "Bi-Weekly",
      "Monthly",
      "Quarterly",
      "Semi-Annual",
      "Annually",
    ]);
    getStandardTasksList(
      {},
      (res) => {
        if (res) {
          //console.log("res",res);
          settaskList(res);
        }
      },
      (resError) => {
        console.log("resError", resError);
      }
    );
  };
  const scrolltoFrequency = () => {
    createTaskRoot !== null &&
      createTaskRoot?.current &&
      createTaskRoot.current.scrollIntoView({
        block: "start",
        inline: "start",
        behavior: "smooth",
      });
  };

  useEffect(() => {
    fetchsettaskList();
    EventEmitter.subscribe("scrolltoCreateTaskItemTop", scrolltoFrequency);
    return () => {
      EventEmitter.unsubscribe("scrolltoCreateTaskItemTop", scrolltoFrequency);
    };
  }, []);

  const setEditData = (editData) => {
    let summaryDataEdit = [];
    if(editData?.shiftData) {
    
    [...Object.keys(editData?.shiftData)].map((shiftId) => {
      let inShiftData = editData?.shiftData[shiftId];
      let inShiftTasksKeys = Object.keys(inShiftData);
      if (inShiftTasksKeys?.length > 0) {
        inShiftTasksKeys.map((taskItemId) => {
          let taskItem = inShiftData[taskItemId];
          let shiftAllData = props?.stepContentValues?.shiftsList?.filter(
            (allsitem) => allsitem?.shiftId === shiftId
          );
          shiftAllData =
            shiftAllData?.length > 0
              ? shiftAllData[0]
              : { shiftId: shiftId, title: taskItem?.title };
          summaryDataEdit.push({
            id: taskItemId,
            frequency: taskItem?.frequency,
            tasks: [...taskItem?.tasksList?.map((titem) => titem.taskId)],
            shift: shiftAllData,
          });
          return taskItem;
        });
      }
      return shiftId;
    });
  }
    let customTasksList = [];
    if (editData?.customTaskList) {
      customTasksList = [
        ...Object.entries(editData.customTaskList).map(([key, value]) => {
          return {
            taskId: key,
            custom: true,
            ...value,
          };
        }),
      ];
    }
    setCustomTaskList(customTasksList);
    setSummaryList(summaryDataEdit);
  };

  useEffect(() => {
    if (props?.editData !== false) {
      setEditData(props?.editData);
    }
  }, [props?.editData, props?.stepContentValues?.shiftsList]);

  const addSummaryListItem = (taskItem) => {
    setSummaryList([...SummaryList, taskItem]);
  };
  const updateSummaryListItem = (id, taskItem) => {
    setSummaryList([
      ...SummaryList.map((item) => (item.id === id ? taskItem : item)),
    ]);
  };

  const removeSummaryListItem = (taskItem) => {
    setSummaryList([
      ...SummaryList.filter((sitem) => {
        return taskItem !== sitem;
      })
    ]);
    
    deleteClientTaskGroup(clientId,taskItem);
  };

  const getTaskTitle = (task) => {
    let allTasks = [...taskList, ...customTaskList];
    let selectedTasks = allTasks.filter((sitem) => task === sitem?.taskId);
    selectedTasks = selectedTasks[0];
    return selectedTasks?.title;
  };

  const validateAndGoNext = () => {
    let errormessages = stepError;
    let isValid = true;
    if (
      typeof SummaryList === "undefined" ||
      SummaryList === "" ||
      SummaryList?.length <= 0
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        SummaryList: "Must Create at least one Task Group",
      };
    } else {
      errormessages = { ...errormessages, SummaryList: "" };
    }

    setStepError(errormessages);
    if (isValid) {
      let SummaryListNew = SummaryList.map((sitem) => {
        let tasksList = sitem?.tasks?.map((stitem) => {
          return { taskId: stitem, title: getTaskTitle(stitem) };
        });
        return { ...sitem, tasksList };
      });
      let customTasksListmap = {};
      customTaskList.map((ctasklistitem) => {
        customTasksListmap[ctasklistitem.taskId] = {
          title: ctasklistitem?.title,
          description: ctasklistitem?.description,
          startDate: ctasklistitem?.startDate || '',
          endDate: ctasklistitem?.endDate || '',
        };
        return ctasklistitem;
      });
      props?.validateAndGoNext &&
        props?.validateAndGoNext(
          { customTaskList:customTasksListmap, SummaryList: SummaryListNew },
          { finalsubmit: true }
        );
    }
  };


  const allTasks = [...taskList];
  return (
    <>
      <Accordion
        square
        disabled={stepId > currentStep}
        expanded={currentStep === stepId}
        onChange={() => goToStep(stepId)}
        className={`step${stepId}`}
      >
        <AccordionSummary
          aria-controls={`panel${stepId}d-content`}
          id={`panel${stepId}d-header`}
          className={
            currentStep === stepId || currentStep > stepId
              ? classes.complated
              : ""
          }
        >
          <Typography className={classes.heading}>
            Assign Task Frequencies
          </Typography>
          <Typography className={classes.secondaryHeading}>
            Step {stepId}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={`stepContent w-100`}>
            {/* Content Starts */}

            <div className="stepSectionContent">
              <div ref={createTaskRoot} className="scrollelementtop">
                <CreateTaskItem
                  shifts={props?.stepContentValues?.shiftsList}
                  taskList={allTasks}
                  standardTasks={taskList ? taskList : []}
                  customtaskList={customTaskList ? customTaskList : []}
                  setCustomTask={setCustomTaskList}
                  taskFrequencies={taskFrequencies}
                  SummaryList={SummaryList}
                  addSummaryListItem={addSummaryListItem}
                  updateSummaryListItem={updateSummaryListItem}
                />
                {typeof stepError?.SummaryList !== "undefined" &&
                  stepError?.SummaryList !== "" && (
                    <span className="small error color-danger">
                      {stepError?.SummaryList}
                    </span>
                  )}
                <div className="Summary p-0 mt-30">
                  <TaskSummary
                    SummaryList={SummaryList}
                    standardTasks={taskList ? taskList : []}
                    customtaskList={customTaskList ? customTaskList : []}
                    removeSummaryListItem={removeSummaryListItem}
                    taskFrequencies={taskFrequencies}
                  />
                </div>
              </div>
            </div>

            {/* Content Ends */}
            <div className="buttonsWrap">
              <Button
                type="button"
                color={"primary"}
                variant={"contained"}
                className="flatbutton themeButton mr-15"
                onClick={() => validateAndGoNext()}
                disabled={loading === true ? true : false}
              >
                {loading === true
                  ? "Loading..."
                  : props?.editData !== false
                  ? "Update"
                  : "Submit"}
              </Button>
              <DialogComponent
                buttonWrapClassName={"d-inline"}
                buttonClassName={"flatbutton"}
                buttonProps={{
                  size: "medium",
                  variant: "contained",
                  disabled: loading === true ? true : false,
                }}
                buttonLabel={"Cancel"}
                ModalTitle={"Are you sure you want to cancel this entry?"}
                ModalContent={<ConfirmCancel onCancelAll={onCancelAll} />}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
export default Step;
