/**
 * Manage Shifts
 * collection Shift
 */

import { firebase } from '../firebase-config';
import { COLLECTIONS } from '../config';

const collection = COLLECTIONS.SHIFTS;
export async function getShiftsList(formData, onSuccess, onError) {
  firebase
    .firestore()
    .collection(collection)
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, shiftId: doc.id, ...data });
      });
      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
      onError && onError(error);
    });
}

export async function getOneShift(id, onSuccess, onError) {
  try {
    firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          onError && onError({ status: false, error: 'Shift not found' });
        }
        let shiftId = doc.id;
        let data = doc.data();
        onSuccess &&
          onSuccess({ status: true, data: { ...data, shiftId: shiftId } });
      })
      .catch((error) => {
        onError && onError({ status: false, error: error });
      });
  } catch (res_1) {
    onError && onError(res_1);
  }
}

export async function addShift(formData, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  firebase
    .firestore()
    .collection(collection)
    .add({
      ...formData,
      createdBy: currentUserId,
      createdAt: new Date().getTime(),
    })
    .then(
      async (doc) => {
        const responseGroupItem = formData;
        responseGroupItem.shiftId = doc.id;
        onSuccess && onSuccess({ status: true, data: responseGroupItem });
      },
      (error) => {
        console.log(`ERROR: ${collection} ${error}`);
        onError({ status: false, error: error });
      }
    )
    .catch((e) => {
      console.log(`ERROR: ${collection} ${e}`);
      onError({ status: false, error: e });
    });
}

export async function updateShift(id, formData, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  let document = await firebase.firestore().collection(collection).doc(id);
  const docContent = {
    ...formData,
    updatedAt: new Date().getTime(),
    updatedBy: currentUserId,
  };
  document
    .set(docContent, { merge: true })
    .then(async (doc) => {
      onSuccess &&
        onSuccess({
          status: true,
          message: 'Updated successfully',
          response: doc,
        });
    })
    .catch((error) => {
      if (error.code === 5) {
        onError && onError({ status: false, message: 'Not Found' });
      }
      onError && onError({ status: false, error: error.code });
    });
}

export async function deleteShift(id, onSuccess, onError) {
  const document = firebase.firestore().collection(collection).doc(id);
  document
    .delete()
    .then(() => {
      onSuccess && onSuccess({ status: true, message: 'Delete successfull' });
    })
    .catch((error) => {
      onError && onError({ status: false, error: error });
    });
}
