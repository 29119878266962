import React from "react";
import { Button } from "@material-ui/core";
import { deleteGroup } from "../../../services/apiservices/groups";
import { Typography } from "@material-ui/core";

const DeleteGroup = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const onDeleteGroup = async () => {
    if(props?.group?.groupId){
      setIsLoading(true);
      await deleteGroup(
       props?.group?.groupId,
        (res) => {
          setIsLoading(false);
          props?.onSuccess && props?.onSuccess();
          props?.onClose && props?.onClose();
        },
        (resError) => {
          setIsLoading(false);
          console.log("error", resError);
        }
      );
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-15">
      <Typography variant="body1">Are you sure you want to Delete " {props?.group?.title} " Group?</Typography>
      <div className="mv-12 text-right">
        <Button
          className="flatbutton button-danger"
          type="button"
          variant={"contained"}
          onClick={onDeleteGroup}
          disabled={isLoading ? true: false}
        >
          {isLoading ? "Loading..." : "Delete"}
        </Button>
        <Button
          className="flatbutton ml-10"
          type="button"
          variant={"contained"}
          disabled={isLoading ? true: false}
          onClick={props?.onClose}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
export default DeleteGroup;
