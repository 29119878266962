import { firebase } from '../firebase-config';
import { COLLECTIONS } from '../config';

/**
 * Get Count of documents
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getDocCount(doc, onSuccess, onError) {
  let colRef = firebase.firestore().collection(doc);
  try {
    colRef
      .get()
      .then((querySnapshot) => {
        let results = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          results.push({ id: doc.id, ...data });
        });
        onSuccess && onSuccess(results);
      })
      .catch((error) => {
        onError && onError({ status: false, error: error });
      });
  } catch (res_1) {
    onError && onError(res_1);
  }
}

export async function getDocCountAl(doc, onSuccess, onError) {



  let colRef = firebase.firestore().collection(doc);
  try {


    colRef
      .orderBy('createdOn', 'desc')
      .get()
      .then((querySnapshot) => {

        let results = [];
        // let i=0;
        // console.log(querySnapshot.docs[0]);
        querySnapshot.forEach((doc) => {
          
          // 1685616872
          // if(doc.createdOn)
          let data = doc.data();
          
          // if(1685620600 > data.createdOn.seconds) {
          //   doc.ref.delete();
          //   console.log(data.createdOn.seconds);
          //   i++;

          // }
          
          
          results.push({ id: doc.id, ...data });
        });
        // console.log(i);
        onSuccess && onSuccess(results);
      })
      .catch((error) => {
        onError && onError({ status: false, error: error });
      });
  } catch (res_1) {
    onError && onError(res_1);
  }
}
