/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import { useEffect } from "react";
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import confirm from "../../../../components/DialogComponent/confirm";
import { EventEmitter } from "../../../../services/event.service";
const useStyles = makeStyles((theme) => ({
  shiftTitle: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "15px",
    backgroundColor: "rgba(0,0,0,0.30)",
    color: "#fff",
  },
  selectwrap: {
    background: "#fff",
    color: "#000",
    minWidth: "170px",
  },
  shiftTaskListwrap: {
    display: "flex",
    alignItems: "center",
  },
  shiftTaskList: {
    marginTop: "10px",
    marginBottom: "20px",
    flex: "1",
    fontSize: "16px",
    lineHeight: "20px",
  },
}));
function ShiftSummaryListItem(props) {
  const classes = useStyles();
  const { item, List } = props;
  const [tasks, setTasks] = useState([]);
  const [selectedFrequency, setSelectedFrequency] = useState("all");

  useEffect(() => {
    let taskslist = [];
    if (selectedFrequency === "all") {
      List?.map((listitem) => {
        taskslist = [...taskslist, ...listitem?.tasks];
        return listitem?.tasks;
      });
    } else {
      let selectedFrequencyTasks = List?.filter(
        (listitem) => listitem?.frequency === selectedFrequency
      );
      selectedFrequencyTasks?.map((listitem) => {
        taskslist = [...taskslist, ...listitem?.tasks];
        return listitem?.tasks;
      });
    }
    getTasksTitles(taskslist);
  }, [selectedFrequency, List, item]);

  const getTasksTitles = (tasks) => {
    let allTasks = [...props?.standardTasks, ...props?.customtaskList];
    let selectedTasks = [
      ...allTasks.filter((sitem) => tasks?.includes(sitem?.taskId)),
    ];
    selectedTasks = [
      ...selectedTasks.map((sitem) => {
        return sitem?.title;
      }),
    ];

    if (selectedTasks?.length > 0) {
      return setTasks(selectedTasks);
    } else {
      return "";
    }
  };

  const onChangeFrequency = (event) => {
    setSelectedFrequency(event?.target?.value);
  };

  let allFrequencyOptions = [
    { label: "All", value: "all" },
    ...List.map((aitem) => {
      return { label: aitem.frequency, value: aitem.frequency };
    }),
  ];

  const OnEditItem = (item) => {
    let selectedItem = List?.filter(
      (litem) => litem?.frequency === selectedFrequency
    );
    // console.log("dfdfsf",item);
    if (selectedItem?.length > 0 ) {
      EventEmitter.dispatch("EditSummaryFrequencyItem", selectedItem[0]);
      EventEmitter.dispatch("scrolltoCreateTaskItemTop");
    }
  };
  const removeTaskGroupSummaryList = (item) => {
    let selectedItem = List?.filter(
      (litem) => litem?.frequency === selectedFrequency
    );
    
    if (selectedItem?.length > 0 && props?.removeSummaryListItem) {
      props?.removeSummaryListItem(selectedItem[0]);
      setSelectedFrequency("all");
    }
   
  };

  return (
    <>
      <div className={classes.shiftTitle}>
        {item}
        <div className={`${classes.selectwrap} ml-auto`}>
          <SingleSelect
            value={selectedFrequency ? selectedFrequency : ""}
            className={`field`}
            name={"selectedFrequency"}
            onChange={onChangeFrequency}
            options={allFrequencyOptions}
          />
        </div>
      </div>
      <div className={classes.shiftTaskListwrap}>
        <div className={classes.shiftTaskList}>{tasks?.join(", ")}</div>
        <div className="actions ml-auto">
          {selectedFrequency !== "all" && (
            <>
              <IconButton
                className="fs-14"
                size="small"
                variant="text"
                onClick={() => OnEditItem(item)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </IconButton>
              <IconButton
                size="small"
                className="color-danger fs-14"
                onClick={() =>
                  confirm(
                    "Are you sure you want to delete the task group?"
                  ).then(() => {
                    removeTaskGroupSummaryList(item);
                  })
                }
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </IconButton>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default function ShiftSummaryList(props) {
  return (
    <ul>
      {props?.SummaryShiftListarr?.map((listItem, index) => {
        let List = props?.SummaryList.filter(
          
          (sitem) => sitem?.shift.title === listItem

        );
        // console.log("vvggg",props?.SummaryList);
        
        return (
          <li key={"summarylistitem" + index}>
            <ShiftSummaryListItem
              item={listItem}
              List={List ? List : []}
              {...props}
            />
          </li>
        );
      })}
    </ul>
  );
}
