import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import CircularProgressWithLabel from "../../../components/CircularProgressWithLabel";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import { acuitygetAppointments } from "../../../services/apiservices/acuity";
import { getTimesheetList } from "../../../services/apiservices/timesheets";
import styles from "../dashboard.module.scss";
import moment from "moment/moment";
const Appointments = (props) => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth()+1;
  let dt = date.getDate();
  let dateit = year+'-' + month + '-'+dt;
  
  const progressValue = 60;
  const [progress, setProgress] = useState();
  const [frequency, setFrequency] = useState(moment().format(moment.HTML5_FMT.DATE));
  const [totalappts, setTotalappts] = useState([]);
  const [completedappts, setCompletedAppts] = useState();
  
  const [timesheetData, setTimesheetData] = useState();
  const [acuityData, setAcuityData] = useState();
  const frequencyList = [
    { label: "Daily", value: `${moment().format(moment.HTML5_FMT.DATE)}` },
    { label: "Yesterday", value: moment().subtract(1, "days").format(moment.HTML5_FMT.DATE) },
    
  ];
  const onChangeInput = (e) => {
    setFrequency(e.target.value);
  };
  const updateProgress = (newval) => {
    setProgress(newval);
 };

 

 const filterByReference = (arr1, arr2) => {
  let res = [];
  res = arr1?.filter(el => {
     return arr2?.find(element => {
        return parseInt(element.appointmentId) === el.id;
     });
  });
  return res?.length;
}

  
  const getTimesheetData = async () => {
    await getTimesheetList({},(res)=>{
      if(res){
        setTimesheetData(res);
        //console.log("timesheets",res);
      }
    });
    
  };

  // useEffect( () => {
  //    getTimesheetData();
  //    acuitygetAppointments(
  //     {minDate: frequency,
  //     maxDate: frequency,
  //     canceled: 'false',
  //   excludeForms: 'false',
  //   direction: 'ASC',
  
  //     },
  //     (res) => {setTotalappts(res?.data?.data.length);
  //       setAcuityData(res?.data?.data);
  //     //console.log("ttappts",res)
  //   },
  //     (error) => console.log("error", error)
  //   );
  //   console.log("timesh",timesheetData);
  //   console.log("acity",acuityData);
    
    
  // }, [frequency]);

  useEffect(() => {
    const getdata = async () => {
      await getTimesheetData();
      await acuitygetAppointments(
        {minDate: frequency,
        maxDate: frequency,
        canceled: 'false',
      excludeForms: 'false',
      direction: 'ASC',
      max: '100',
    
        },
        async (res) => {setTotalappts(res?.data?.data.length);
          let countfil;
          await getTimesheetList({},(res2)=>{
            if(res2){
              setTimesheetData(res2);
              setCompletedAppts(filterByReference(res?.data?.data,res2));
              
              updateProgress((100/res?.data?.data.length)*filterByReference(res?.data?.data,res2));
              
              //console.log("timesheets",res);
            }
          });

          //console.log("tm",timesheetData);
          
          setAcuityData(res?.data?.data);
          setCompletedAppts(countfil);
          
          updateProgress((100/totalappts)*countfil);
          //console.log("ttappts",countfil)
      },
        (error) => console.log("error", error)
      );
      
    };
  
      getdata(); // run it, run it

    //  if(timesheetData!=="undefined" && acuityData!=='undefined'){
    //   setCompletedAppts(filterByReference(acuityData,timesheetData));
    //   updateProgress((100/totalappts)*completedappts);     
    // }else{
    //         setCompletedAppts(0);
    //         updateProgress(0);
            
    //       }
          
  }, [frequency]);

// console.log('list',acuityData);

  function filter_dates(event) {
    return event.id === 917072101;
  }
  var filtered = acuityData?.filter(filter_dates);
  
  //console.log(filterByReference(acuityData,timesheetData));
  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12} sm={7}>
        <p className="p-0 m-0 mb-5 fonthe">
          Appointments
          </p>
          <SingleSelect
            //   label="Frequency"
            value={frequency}
            className={`field newonr`}
            name={"frequency"}
            onChange={(e) => onChangeInput(e)}
            options={frequencyList}
          />
        </Grid>
        
        <Grid item xs={12} sm={5} className="text-right">
          <CircularProgressWithLabel signt={"yes"} value={isNaN(progress)?0:progress} wrapclass="color-appts" />
        </Grid>
       
      </Grid>

      <ul>
        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              Total Appointments{" "}
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{totalappts}</strong>
            </Grid>
          </Grid>
        </li>
        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              Pending
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{totalappts-completedappts}</strong>
            </Grid>
          </Grid>
        </li>
        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              Completed
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{completedappts}</strong>
            </Grid>
          </Grid>
        </li>
      </ul>
    </>
  );
};
export default Appointments;
