/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Grid,
  Container,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { useParams } from "react-router";
import PageTitle from "../../components/pageTitle/PageTitle";
import CommonContext from "../../hooks/commonContext";
import SingleSelect from "../../components/SelectField/SingleSelect";
import MultiSelect from "../../components/SelectField/MultiSelect";
import { getEmployeesList } from "../../services/apiservices/employee";
import confirm from "../../components/DialogComponent/confirm";
import { useNavigate } from "react-router";
import { saveChannel, getChannel, updateChannel } from "../../services/apiservices/messages";

const GroupType = [
  { value: "Public", label: "Public Group" },
  { value: "Private", label: "Private Group" },
];
function CreateChannel(props) {
  const commonContext = useContext(CommonContext);
  const history = useNavigate();
  const { channelId } = useParams();
  const [formerror, setFormError] = useState({});
  const [formdata, setFormData] = useState({ groupType: "Private" });

  const [audienceList, setAudienceList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const setIsLoading = (status) => {
    commonContext.setLoader(status);
  };

  const getChannelDetails = (channelId) => {
    if (typeof channelId !== "undefined" && channelId !== "") {
      commonContext?.setLoader(true);
      getChannel(
        channelId,
        (res) => {
          if (res?.status === true) {
            const audList = [];
            const selectedEmp = res?.data?.employee?.map((itm) => {
              audList.push(itm);
              return itm.id;
            });
            setAudienceList(audList);
            setFormData({
              ...res?.data,
              groupName: res?.data.groupName,
              selectedEmployees: selectedEmp,
              groupType: res?.data.type,
              message: res?.data.description,
              channelId: channelId,
            });
          }
          commonContext?.setLoader(false);
        },
        (resError) => {
          if (resError?.error === "Channel not found") {
            history("/message-board/manage-channel");
          }
          commonContext?.setLoader(false);
        }
      );
    } else {
      setFormData({ groupType: "Private" });
      commonContext?.setLoader(false);
    }
  };
  const handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setFormData({ ...formdata, [name]: value });
    setFormError({ ...formerror, [name]: "" });
  };

  const clearformdata = () => {
    setFormData({ groupType: "Private" });
    setFormError({});
    setAudienceList([]);
  };
  const fetchData = async () => {
    setIsLoading(true);

    await getEmployeesList(
      {},
      (res) => {
        if (res) {
          let allEmp = res?.map((eitem) => {
            return {
              ...eitem,
              userName: `${eitem?.firstName} ${eitem?.lastName}`,
            };
          });
          setEmployeeList(allEmp);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      },
      (resError) => {
        console.log("reserror", resError);
        setIsLoading(false);
      }
    );
  };
  useEffect(() => {
    getChannelDetails(channelId);
  }, [channelId]);

  useEffect(() => {
    setIsLoading(true);
    clearformdata();
    setAudienceList([]);
    fetchData();

    setIsLoading(false);
  }, []);

  const handlefieldChange = (name, value) => {
    setFormData({ ...formdata, [name]: value });
    setFormError({ ...formerror, [name]: "" });

    if (name === "selectedEmployees" && value) {
      const empList = employeeList.filter((emp) => value.includes(emp.id));
      setAudienceList([
        ...empList?.map((aitem) => {
          return {
            firstName: aitem.firstName,
            lastName: aitem.lastName,
            id: aitem.id,
            email: aitem.email,
            phone: aitem.phoneNumber,
          };
        }),
      ]);
    }
  };

  const checkValid = () => {
    let errormessages = formerror;
    let isValid = true;
    if (
      typeof formdata?.groupName === "undefined" ||
      formdata?.groupName === ""
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        groupName: "Field is required",
      };
    } else {
      errormessages = { ...errormessages, groupName: "" };
    }
    if (formdata.groupType === "Private") {
      if (audienceList.length <= 0) {
        isValid = false;
        errormessages = {
          ...errormessages,
          selectedEmployees:
            "Please Add employees By Name/By Client/By Group First!",
        };
      } else {
        errormessages = { ...errormessages, selectedEmployees: "" };
      }
    } else {
      errormessages = { ...errormessages, selectedEmployees: "" };
    }
    if (typeof formdata?.message === "undefined" || formdata?.message === "") {
      isValid = false;
      errormessages = {
        ...errormessages,
        message: "Field is required",
      };
    } else {
      errormessages = { ...errormessages, message: "" };
    }

    setFormError(errormessages);
    return isValid;
  };

  const onSaveChannel = () => {
    if (true === checkValid()) {
      setIsLoading(true);
      let formDataSave = {
        groupName: formdata?.groupName,
        description: formdata?.message,
        type: formdata?.groupType,
        status: true,
        employee: audienceList
          ? audienceList.map((empitem) => {
              return {
                id: empitem.id,
                firstName: empitem.firstName,
                lastName: empitem.lastName,
                email: empitem.email,
                phone: empitem.phone,
              };
            })
          : [],
      };

      if (typeof channelId === "undefined" || channelId === "") {
        saveChannel(
          formDataSave,
          (res) => {
            if (res.status === true) {
              setIsLoading(false);
              history("/message-board/manage-channel");
            }
          },
          (errorRes) => {
            setIsLoading(false);
            console.log("error while save notification", errorRes);
          }
        );
      } else {
        updateChannel(
          channelId,
          formDataSave,
          (res) => {
            if (res.status === true) {
              setIsLoading(false);
              history("/message-board/manage-channel");
            }
          },
          (errorRes) => {
            setIsLoading(false);
            console.log("error while save notification", errorRes);
          }
        );
      }
    }
  };

  let allempoptions = employeeList?.map((item) => {
    return { label: item.firstName + item.lastName, value: item.id };
  });
  
  return (
    <Container>
      <PageTitle
        pageTitle="Create Channel"
        showBreadcrumb={[{ label: "Home", to: "/" }]}
        showLastSeen={true}
      />

      <div className={`sectionBox`}>
        <Grid container spacing={2} className={"mt-20 pb-15"}>
          <Grid item sm={12} md={6}>
            <h3 className="sectiontitle ntop">Add New Chat/Group</h3>

            <div className="mb-20">
              <TextField
                fullWidth={true}
                size="small"
                name="groupName"
                id="groupName"
                label="Group Name *"
                InputLabelProps={{shrink:true}}
                variant="outlined"
                value={formdata.groupName}
                onChange={handleInputChange}
                placeholder="Enter Group Name"
              />
              {typeof formerror?.groupName !== "undefined" &&
                formerror?.groupName !== "" && (
                  <span className="small error color-danger">
                    {formerror?.groupName}
                  </span>
                )}
            </div>
            <div className="mb-20">
              <SingleSelect
                label="Type"
                className={`field`}
                name={"groupType"}
                InputLabelProps={{shrink:true}}
                value={formdata.groupType}
                onChange={(e) => handlefieldChange("groupType", e.target.value)}
                options={GroupType}
              />
            </div>
            {formdata.groupType === "Private" && (
              <div className="mb-20">
                <MultiSelect
                  label="Find Employees by Name"
                  value={
                    formdata?.selectedEmployees
                      ? [...formdata?.selectedEmployees]
                      : []
                  }
                  InputLabelProps={{shrink:true}}
                  className={`field`}
                  name={"selectedEmployees"}
                  onChange={(val) =>
                    handlefieldChange("selectedEmployees", val)
                  }
                  selectAllOption={true}
                  options={[...allempoptions]}
                />
                <br />
                {typeof formerror?.selectedEmployees !== "undefined" &&
                  formerror?.selectedEmployees !== "" && (
                    <span className="small error color-danger">
                      {formerror?.selectedEmployees}
                    </span>
                  )}
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container className="message_container">
          <Grid item sm={12} md={6}>
            <TextField
              value={formdata?.message ? formdata?.message : ""}
              onChange={(e) => handlefieldChange("message", e.target.value)}
              fullWidth
              id="message"
              InputLabelProps={{shrink:true}}
              label="Type Description here"
              multiline
              rows={4}
              // defaultValue="Type Your message here"
              variant="outlined"
            />
            {typeof formerror?.message !== "undefined" &&
              formerror?.message !== "" && (
                <span className="small error color-danger">
                  {formerror?.message}
                </span>
              )}
          </Grid>
        </Grid>
        <Grid container className="buttonwrap mv-15">
          <Grid item sm={12} md={12}>
            <Button
              variant="contained"
              color="primary"
              className="themebutton flatbutton mr-12"
              onClick={onSaveChannel}
            >
              Save
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="themebutton flatbutton mr-12"
              onClick={clearformdata}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              className="themebutton flatbutton "
              onClick={() => {
                confirm(
                  "Are you sure you want to cancel , this wont be saved/ send ?"
                ).then(() => {
                  history("/message-board/manage-channel");
                });
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default CreateChannel;
