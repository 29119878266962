import React, { useState, useEffect } from "react";
import {useNavigate } from 'react-router-dom';
import { Button,IconButton, Checkbox, Typography } from "@material-ui/core";
import ConfirmCancel from "../../../components/DialogComponent/ConfirmCancel";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../../components/AccordionComponent/AccordionComponent";
import DialogComponent from "../../../components/DialogComponent/DialogComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit,faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import CreateShift from "./CreateShift";
import confirm from "../../../components/DialogComponent/confirm";
import confirmnew from "../../../components/DialogComponent/confirmnew";
import { getShiftsList } from "../../../services/apiservices/shifts";
import { deleteClientShift } from "../../../services/apiservices/client";
import { useParams } from "react-router";
const stepId = 3;
function Step3(props) {
  const [stepError, setStepError] = useState({});
  const [shiftsList, setShiftsList] = useState([]);
  const [allShifts, setAllShifts] = React.useState([]);
  const [shiftDelete,setShiftDelete] = useState();
  const { clientId } = useParams();
  let history = useNavigate();
  const {
    currentStep,
    goToStep,
    onCancelAll,

    loading,
    classes,
  } = props;

  const callfetchShifts = () => {
    getShiftsList(
      {},
      (res) => {
        if (res) {
          setAllShifts(res);
        }
      },
      (resError) => {
        console.log("resError", resError);
      }
    );
  };
  useEffect(() => {
    callfetchShifts();
  }, []);

  const setEditData = (editData) => {
    if(editData?.shiftData) {
    let shfitdata = [...Object.keys(editData?.shiftData)].map((shiftId) => {
      let inShiftData = editData?.shiftData[shiftId];
      let inShiftDataKeys = Object.keys(inShiftData);
      let shiftItemData = inShiftData[inShiftDataKeys[0]]; 
      //console.log(shiftItemData?.titlen);
      return {
        shiftId: shiftId,
        title: shiftItemData?.title,
        titlen:shiftItemData?.titlen,
        day: shiftItemData?.day,
        status: true,
        startTime: shiftItemData?.timeRange?.startTime,
        endTime: shiftItemData?.timeRange?.endTime,
        startDate: shiftItemData?.timeRange?.startDate,
        endDate: shiftItemData?.timeRange?.endDate,
        opentasksstatus:shiftItemData?.timeRange?.opentasksstatus
      }
    });
    
      setShiftsList(shfitdata);
    }
  }

  useEffect(() => {
    if(props?.editData !== false){
      setEditData(props?.editData);
    }
    
  }, [props?.editData]);

  const onCreateShift = (shiftData) => {
    let sendShiftData = [...shiftsList, shiftData];
    setShiftsList(sendShiftData);
    resetError();
    
  };

  

  const onUpdateShift = (shiftId, shiftData) => {
    if (shiftId && shiftsList?.some((sitem) => sitem.shiftId === shiftId)) {
      let sendShiftData = [
        ...shiftsList.map((sitem) =>
          sitem.shiftId === shiftId ? shiftData : sitem
        ),
      ];
      setShiftsList(sendShiftData);
      
    } else {
      let sendShiftData = [...shiftsList, shiftData];
      setShiftsList(sendShiftData);

    }
  };

  
const getTasktl = (shiftItem)=>{
  
  let shiftid = shiftItem.shiftId;
    let shiftidcon = props?.editData;
    let shifttt = shiftidcon?.shiftData[shiftid];
   
    let inShiftDataKeyss = Object.keys(shifttt);

   
    //const keys = Object.keys(shiftttt);
   const ttt = inShiftDataKeyss.map((taaskt)=>{
    

return (shifttt[taaskt].tasksList).length;
    });
    var total = 0;
for (var i in ttt) {
  total += ttt[i];
}
    return total;
    

}
  const onDeleteShiftData = async (index, shiftItem) => {

    
    let sendShiftData = [
      ...shiftsList.filter((sitem) => sitem.shiftId !== shiftItem.shiftId),
    ];
    
    deleteClientShift(clientId,shiftItem)
    .then(()=>{ 
      history({
        pathname:`/createClient/${clientId}`,
        state:{
          stepid:"3"
         }
       });
      window.location.reload();
      
      //window.location.reload(false);
    });
    setShiftDelete(sendShiftData);
    setShiftsList(sendShiftData);
    
  };

  const handleShiftCheckChange = (event, shiftId) => {
    const {
      target: { checked },
    } = event;
    let newShiftListData = [
      ...shiftsList.map((shiftListItem) => {
        return shiftId === shiftListItem?.shiftId
          ? { ...shiftListItem, status: checked }
          : shiftListItem;
      }),
    ];
    
    setShiftsList(newShiftListData);
  };

  const resetError = () => {
    setStepError({});
  };

  const validateAndGoNext = () => {
    
    let errormessages = stepError;
    let isValid = true;
    if (
      typeof shiftsList === "undefined" ||
      shiftsList === "" ||
      shiftsList?.length <= 0
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        shiftsList: "Must Create at least one Shift",
      };
    } else {
      errormessages = { ...errormessages, shiftsList: "" };
    }
    setStepError(errormessages);
    if (isValid)
      props?.validateAndGoNext &&
        props?.validateAndGoNext({ shiftsList: shiftsList });
      
  
  };
 //console.log(shiftsList);

  return (
    <>
      <Accordion
        square
        disabled={stepId > currentStep}
        expanded={currentStep === stepId}
        onChange={() => goToStep(stepId)}
        className={`step${stepId}`}
      >
        <AccordionSummary
          aria-controls={`panel${stepId}d-content`}
          id={`panel${stepId}d-header`}
          className={
            currentStep === stepId || currentStep > stepId
              ? classes.complated
              : ""
          }
        >
          <Typography className={classes.heading}>Create Shifts</Typography>
          <Typography className={classes.secondaryHeading}>
            Step {stepId}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={`stepContent w-100`}>
            {/* Content Starts */}

            <div className="stepSectionContent">
              <CreateShift
                allShifts={allShifts ? allShifts : []}
                shiftsList={shiftsList}
                onCreateShift={onCreateShift}
                resetError={resetError}
              />

              {typeof stepError?.shiftsList !== "undefined" &&
                stepError?.shiftsList !== "" && (
                  <span className="small error color-danger">
                    {stepError?.shiftsList}
                  </span>
                )}
              <div className="shiftSummary mt-15">
                <h5 className="stepSectionTitle">Shift Summary</h5>
                <ul className="shiftList pl-15">
                 
                  {shiftsList.map((shiftItem, index) => {
                    
                    //   alltitlse = shiftsList.map((title)=>{
                    //     let ct;
                    //     if(title.title === shiftItem?.title ){
                    //       ct = title.title
                          
                    //     }
                    //     return ct;
                    //   });

                    //   alltitlse = alltitlse.filter(function( element ) {
                    //     return element !== undefined;
                    //  });
                     
                    return (
                      <li
                        className="shiftListItem formFieldwrap"
                        key={"shiftsummaryitem" + index}
                      >
                        <div className=" checkboxfieldwrap status">
                          <Checkbox
                            size="small"
                            color="primary"
                            checked={shiftItem?.status}
                            className="smallcheckbox"
                            onChange={(event) =>
                              handleShiftCheckChange(event, shiftItem?.shiftId)
                            }
                            name={shiftItem?.label + "status"}
                          />
                        </div>
                        
                        
                        
                        <div className="shiftname">{shiftItem?.title}{shiftItem?.titlen!==0 ? "-" +shiftItem?.titlen :""}</div>
                        <div className="shiftday">
                          {shiftItem?.day?.map(
                            (dayitem, index) =>
                              dayitem?.toLowerCase().substring(0, 3) +
                              (index < shiftItem?.day?.length - 1 ? "-" : "")
                          )}
                        </div>
                        <div className="shifttime">
                        {/* {shiftItem?.startTime + "-" + shiftItem?.endTime} */}
                      </div>
                        <div className="actions ml-auto">
                          <DialogComponent
                            buttonWrapClassName={"d-inline"}
                            buttonClassName={"flatbutton iconbutton"}
                            buttonProps={{ size: "medium", variant: "link" }}
                            buttonLabel={<FontAwesomeIcon icon={faEdit} />}
                            ModalTitle={`Edit ${shiftItem?.title} Shift`}
                            ModalContent={
                              <CreateShift
                                allShifts={allShifts ? allShifts : []}
                                edit={shiftItem?.shiftId}
                                shiftData={shiftItem}
                                shiftsList={shiftsList}
                                onCreateShift={onUpdateShift}
                              />
                            }
                          />

                          <IconButton
                            size="small"
                            className="color-danger"
                            onClick={async() => {
                              let conts = getTasktl(shiftItem);

                              confirm(
                                `Are you sure you want to delete the shift?
                                Total Tasks-${conts}`
                              ).then(() => {
                                onDeleteShiftData(index, shiftItem);
                              })
                              
                            }
                            }
                          >
                            <FontAwesomeIcon icon={faTimesCircle} />
                          </IconButton>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {/* Content Ends */}
            <div className="buttonsWrap">
              <Button
                type="button"
                color={"primary"}
                variant={"contained"}
                className="flatbutton themeButton mr-15"
                onClick={() => validateAndGoNext()}
                disabled={loading === true ? true : false}
              >
                {loading === true ? "Loading..." : "Next"}
              </Button>
              <DialogComponent
                buttonWrapClassName={"d-inline"}
                buttonClassName={"flatbutton"}
                buttonProps={{
                  size: "medium",
                  variant: "contained",
                  disabled: loading === true ? true : false,
                }}
                buttonLabel={"Cancel"}
                ModalTitle={"Are you sure you want to cancel this entry?"}
                ModalContent={<ConfirmCancel onCancelAll={onCancelAll} />}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
export default Step3;
