import React from "react";
import { Container, TextField } from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import ThemeTable from "../../components/Table/ThemeTable";
import { Columns } from "./groupsTableColumns";
import DialogComponent from "../../components/DialogComponent/DialogComponent";
import TableToolbar from "../../components/Table/TableToolbar";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DeleteGroup from "./Groups/DeleteGroup";
import EditGroup from "./Groups/EditGroup";
import EditIcon from "@material-ui/icons/Edit";
import { useEffect } from "react";
import { getGroupsList } from "../../services/apiservices/groups";
import AddNewGroup from "./Groups/AddNewGroup";

const ManageGroups = (props) => {
  const [allGroups, setAllGroups] = React.useState([]);
  const [searchGroupTerm, setSearchGroupTerm] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const fetchGroupsList = async () => {
    setIsLoading(true);
    getGroupsList(
      {},
      (res) => {
        if (res) {
          setAllGroups(res);
          setIsLoading(false);
        }
      },
      (resError) => {
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchGroupsList();
  }, []);

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "action":
        return (
          <>
            <DialogComponent
              buttonWrapClassName={"d-inline"}
              buttonClassName={"iconButton color-primary"}
              buttonProps={{ size: "small" }}
              buttonLabel={<EditIcon />}
              ModalTitle={"Update Group"}
              ModalContent={
                <EditGroup group={rcellItem} onSuccess={fetchGroupsList} />
              }
            />

            <DialogComponent
              buttonWrapClassName={"d-inline"}
              buttonClassName={"iconButton color-danger"}
              buttonProps={{ size: "small" }}
              buttonLabel={<DeleteOutlineIcon />}
              ModalTitle={"Remove ?"}
              ModalContent={
                <DeleteGroup group={rcellItem} onSuccess={fetchGroupsList} />
              }
            />
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  const tableRightComponent = () => {
    return (
      <div className="d-flex groupsearch">
        <div className=" mh-12">
          <TextField
            fullWidth={true}
            size="small"
            name="searchGroupTerm"
            id="searchGroupTerm"
            label=""
            variant="outlined"
            placeholder="Search Group..."
            className="input"
            onChange={(event) => {
              setSearchGroupTerm(event.target.value);
            }}
            value={searchGroupTerm ? searchGroupTerm : ""}
          />
        </div>
        <DialogComponent
          buttonWrapClassName={"d-inline"}
          buttonClassName={"flatbutton w-norm"}
          buttonProps={{
            size: "small",
            color: "primary",
            variant: "contained",
          }}
          buttonLabel={"Add New Group"}
          ModalTitle={"Add New Group"}
          ModalContent={<AddNewGroup onAddSuccess={fetchGroupsList} />}
        />
      </div>
    );
  };

  const filteredGroups =
    searchGroupTerm !== ""
      ? allGroups?.filter((group) =>{
        return  String(group.title)
        .toLowerCase()
        .includes(String(searchGroupTerm).toLowerCase()) || String(group.description)
        .toLowerCase()
        .includes(String(searchGroupTerm).toLowerCase())
      })         
      : allGroups;
  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Groups"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Employee", to: "/manageEmp" },
          ]}
          showLastSeen={true}
        />
        <div className={`sectionBox`}>
          <TableToolbar title="Groups" rightComponent={tableRightComponent()} />
          <ThemeTable
            rows={filteredGroups}
            headCells={Columns}
            hidePagination={true}
            renderRowCell={renderRowCell}
            // selectedRows={selectedRows}
            isLoading={isLoading}
          />
        </div>
      </Container>
    </>
  );
};
export default ManageGroups;
