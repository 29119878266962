import React, { useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import {
  Button,
  Typography,
  Container,
  CssBaseline,
} from "@material-ui/core";
import Input from '@material-ui/core/Input';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

// import { sendrecoveremail } from "../../services/apiservices/auth";
import { updatepasswordem } from "../../services/firebase";
import { withStyles } from "@material-ui/core/styles";
import AuthContext from "../../hooks/authContext";
import loginBg from "../../assets/images/pc_loginBg.jpg";
import cmsAdminLogo from "../../assets/images/cmsAdminLogo.png";
const styles = (theme) => ({
  pageroot: {
    background: 'url(' + loginBg + ') top center no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    height: '100vh',
    paddingTop: theme.spacing(2),
  },
  paper: {
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
    background: '#078037',
    color: '#fff',
    '&:hover': {
      background: '#078037'
    }
  },
});
const ResetPassword = (props) => {
  let history = useNavigate();
  const auth = useContext(AuthContext);
  const queryParameters = new URLSearchParams(window.location.search)
  const mode = queryParameters.get("mode")
  const oobCode = queryParameters.get("oobCode")
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  //console.log("code",mode,oobCode);
  const showErrorMessage = (message) => {
    setErrorMessage(message);
    setLoading(false);
  }
  const updatePass = async(e)=>{
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setLoading(true);

    
    const pass = validatePassword(password)
if(password !== confirmPassword){
  setErrorMessage("Passwords do not match");
}else{

    if (pass) {
      
        
        await updatepasswordem(oobCode,password,  () => {
          
          setLoading(false);
            
            setSuccessMessage("Password Updated, Redirecting to Login...");
            setTimeout(()=>{
              history('/login');
            },2000);
        },
        (resError) => {
          setLoading(false);
          showErrorMessage(resError?.message || "Something Went Wrong!");
        })
      
    } else {
      
    }
  }
    setLoading(false);
  }

  
  const validatePassword = (password) => {
    if (password.length < 8) {
      setErrorMessage("Password must be at least 8 characters long");
      return false;
    }
    if (!/\d/.test(password)) {
      setErrorMessage("Password must contain at least one number");
      return false;
    }
    if (!/[a-z]/.test(password)) {
      setErrorMessage("Password must contain at least one lowercase letter");
      return false;
    }
    if (!/[A-Z]/.test(password)) {
      setErrorMessage("Password must contain at least one uppercase letter");
      return false;
    }
    setErrorMessage("");
    return true;
  };
  const handleInputChange = (e) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
  };
  function handlePaste(e) {
    e.preventDefault();
  }
  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };
  const { classes } = props;
  return (
    <div className={classes.pageroot}>
      
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={`${classes.paper} loginwrap sectionBox`}>
          <img className="cmslogo" src={cmsAdminLogo} alt="PROTeam Login" />
          <form className={classes.form} noValidate>
            <div className="formFieldGroup">
              {/*<InputLabel>Username</InputLabel>*/}
              <Input
                fullWidth={true}
                disableUnderline={true}
                onChange={handleInputChange}
                value={password}
                className="input"
                name="password"
                id="password"
                placeholder="Enter New Password"
                type={showPassword ? "text" : "password"}
                startAdornment={<span className="input-adornment"><FontAwesomeIcon icon={faPencilAlt} onClick={togglePasswordVisibility} /></span>}
              />
              <Input
                fullWidth={true}
                disableUnderline={true}
                onChange={event => setConfirmPassword(event.target.value)}
                value={confirmPassword}
                className="input"
                name="confrimpassword"
                id="confrimpassword"
                placeholder="Confirm Password"
                type={showPassword ? "text" : "password"}
                onPaste={handlePaste}
                startAdornment={<span className="input-adornment"><FontAwesomeIcon icon={faPencilAlt} onClick={togglePasswordVisibility} /></span>}
              />
            </div>
            <Button
              type="submit"
              fullWidth={true}
              className={`${classes.submit}`}
              color={"primary"}
              variant={"contained"}
              onClick={updatePass}
              
            >
              {"UPDATE PASSWORD"}
            </Button>
            {errorMessage !== "" && (
              <Typography className="text-center" color="error" variant="body2">
                {errorMessage}
              </Typography>
            )}
            {successMessage !== "" && (
              <Typography className="text-center" color="primary" variant="body2">
                {successMessage}
              </Typography>
            )}
            <Typography className="text-center mt-25" variant="body2">
              Already Registred ? <a className="color-blue" href="/login"> Click here</a>
            </Typography>
            
          </form>
        </div>
      </Container>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ResetPassword);
