import { firebase } from '../firebase-config';
import { COLLECTIONS } from '../config';

const collection = COLLECTIONS.USER_ACTIVITY_LOG;


/**
 * Get Data from USER_ACTIVITY_LOG collection
 * @param {*} id
 * @param {*} onSuccess
 * @param {*} onError
 */

export async function getUserActivity(userIds, onSuccess, onError) {

  let colRef = firebase.firestore().collection(collection);

  try {

    let userDataArray = [];

    for (let userId of userIds) {

      let querySnapshot = await colRef.where('userId', '==', userId).get();

      querySnapshot.forEach((doc) => {
        let userData = doc.data();
        userDataArray.push(userData);
      });

    }
    return userDataArray;

  } catch (res_1) {
    onError && onError(res_1);
  }
}
