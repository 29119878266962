import { Container } from "@material-ui/core";
import React, { useContext } from "react";
import AuthContext from "../../hooks/authContext";
// import cmsAdminLogo from "../../assets/images/cmsAdminLogo.png";

const Unauthorized = () => {
  const auth = useContext(AuthContext);
  return <Container maxWidth="lg" className="sectionBox">
    {/* <div className={` text-center logo w-100`}><img src={cmsAdminLogo} alt="CMS" /></div> */}
    {auth?.isLoading !== true && <h3 className={` text-center`}>You May Not Authorized to Access this page.</h3>}
    {auth?.status !== true && <p className={` text-center`}><a href="/login">Login</a></p>}
  </Container>
};

export default Unauthorized;
