
const Columns = [
  // { id: "checkbox", numeric: true, label: "", isSortable: false },
  {
    id: 'createdOn',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Title',
  },
  {
    id: 'clientname',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',
  },
  {
    id: 'empname',
    numeric: false,
    disablePadding: false,
    label: 'Employee Name',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: 'Action',
    rowclassName: 'text-right',
    className: 'text-right pr-30',
  },
];

const Rows = [
  {
    id: 'F6BFpMVq8ujY3Q0G6tLv',
    description: 'New Client Careted',
    title: 'Client Created!',
    empname: 'iqOqnrVa0IbU2CZblWubYOsPK3l2',
    type: 'newcl',
    createdOn: {
      seconds: 1674890924,
      nanoseconds: 0,
    },
    clientname: 'gk',
  },
  {
    id: 'F6BFpMVq8ujY3Q0G6tLv',
    type: 'newcl',
    clientname: 'gk',
    empname: 'iqOqnrVa0IbU2CZblWubYOsPK3l2',
    title: 'Client Created!',
    createdOn: {
      seconds: 1674890924,
      nanoseconds: 0,
    },
    description: 'New Client Careted',
  },
  {
    id: 'F6BFpMVq8ujY3Q0G6tLv',
    type: 'newcl',
    clientname: 'gk',
    empname: 'iqOqnrVa0IbU2CZblWubYOsPK3l2',
    title: 'Client Created!',
    createdOn: {
      seconds: 1674890924,
      nanoseconds: 0,
    },
    description: 'New Client Careted',
  },
  {
    id: 'F6BFpMVq8ujY3Q0G6tLv',
    type: 'newcl',
    clientname: 'gk',
    empname: 'iqOqnrVa0IbU2CZblWubYOsPK3l2',
    title: 'Client Created!',
    createdOn: {
      seconds: 1674890924,
      nanoseconds: 0,
    },
    description: 'New Client Careted',
  },
  {
    id: 'F6BFpMVq8ujY3Q0G6tLv',
    type: 'newcl',
    clientname: 'gk',
    empname: 'iqOqnrVa0IbU2CZblWubYOsPK3l2',
    title: 'Client Created!',
    createdOn: {
      seconds: 1674890924,
      nanoseconds: 0,
    },
    description: 'New Client Careted',
  },
];

export { Columns, Rows };
