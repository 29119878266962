/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { Container,TextField } from "@material-ui/core";
import moment from "moment-timezone";
import PageTitle from "../../components/pageTitle/PageTitle";
import ThemeTable from "../../components/Table/ThemeTable";
import CommonContext from "../../hooks/commonContext";
import { chagedtime } from "../../services/helpers";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Columns } from "./AlertsTableColumns";
import DialogComponent from "../../components/DialogComponent/DialogComponent";
import AlertsDetails from "./AlertsDetails";
import { getalertsList } from "../../services/apiservices/alerts";
import SingleSelect from "../../components/SelectField/SingleSelect";
import TableToolbar from "../../components/Table/TableToolbar";

export const alertList = [
  { label: "All", value: "all" },
  { label: "Employee - No Check In", value: "nocheckin" },
  { label: "Employee - No Check Out", value: "nocheckout" },
  { label: "Client Added To Emp", value: "emptocl" },
  { label: "Client Removed from Emp", value: "emptoclr" },
  { label: "Employee - Late Check In", value: "checkinlate" },
  { label: "Client - New", value: "newcl" },
];
function AlertsPage() {
  const commonContext = useContext(CommonContext);
  const [allAlerts, setAllAlerts] = useState([]);
  const [filterAlerts, setFilterAlerts] = useState([]);
  const [filterDate, setFilterDate] = useState(moment().format("YYYY-MM-DD"));
  const [alertType, setAlertType] = useState("all");

  
  

  const setAlertData = () => {
    setFilterAlerts(()=> allAlerts.filter(alert => {
      if(alertType !== 'all') {
        return alert.type === alertType && moment.unix(alert.createdOn.seconds).format("YYYY-MM-DD") === filterDate
      }
      return  moment.unix(alert.createdOn.seconds).format("YYYY-MM-DD") === filterDate
    }));
  }
  useEffect(()=>{
    setAlertData();
  }, [allAlerts, filterDate, alertType]);
  const fetchAlertsList = async () => {
    commonContext?.setLoader(true);
    await getalertsList(
      async (res) => {
         setAllAlerts(res);
        //  setFilterAlerts(() => res.filter(alert => (alert.createdOn.seconds));
          commonContext?.setLoader(false);
      },
      (resError) => {
        console.log("reserror", resError);
        commonContext?.setLoader(false);
      }
      
    );
    
    // console.log(allNotifications);
    // await setAllNotifications(Rows);
    // commonContext?.setLoader(false);
  };
  useEffect(() => {
    fetchAlertsList();
  }, []);

  const onChangeInput = async(e) => {
    setAlertType(e.target.value);
  };
  //   const tableLeftComponent = () => {
  //     return <div className="d-flex flex-center"></div>;
  //   };
  //   const tableRightComponent = () => {
  //     return (
  //       <div className="d-flex flex-center groupsearch"></div>
  //     );
  //   };
  const handleDateChange = (newValue) => {
    setFilterDate(newValue.target.value);
  };
  const tableRightComponent = () => {
    return (
      <div className="d-flex groupsearch3 editEmp issueAlign">
        <div className=" mr-10 mv-7">
          <TextField
            name="date"
            value={filterDate}
            fullWidth={true}
            size="small"
            variant="outlined"
            className={`field`}
            type={"date"}
            inputProps={{
              className: "p-10",
              min: moment().add(-30, "days").format("YYYY-MM-DD"),
              max: moment().add(15, "days").format("YYYY-MM-DD"),
            }}
            onChange={handleDateChange}
          />
        </div>
        <div className="alertselect mv-7">
          <SingleSelect
            value={alertType}
            className={`field `}
            name={"sort"}
            onChange={(e) => onChangeInput(e)}
            options={alertList}
          />
        </div>
      </div>
    );
  };
  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "createdOn":
        return <>{chagedtime(rcellItem.createdOn)} </>;
      case "message":
        return <>{rcellItem?.message?.substring(0, 30) + "..."} </>;
      case "clientname":
        return typeof rcellItem[hcellItem?.id] === 'object' ? '' : rcellItem[hcellItem?.id];
        case "type":
          return (alertList.find((itm) => itm.value === rcellItem[hcellItem?.id]))?.label;
      case "action":
        return (
          <>
            <DialogComponent
              buttonWrapClassName={"d-inline"}
              buttonClassName={"iconButton color-primary"}
              buttonProps={{ size: "small" }}
              buttonLabel={<VisibilityIcon />}
              ModalTitle={"Alert Details"}
              ModalContent={<AlertsDetails item={rcellItem} />}
            />
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };
  // let filteredList = allAlerts;
  return (
    <Container>
      <PageTitle
        pageTitle="Alerts"
        showBreadcrumb={[{ label: "Home", to: "/" }]}
        showLastSeen={true}
      />

      <div className={`sectionBox`}>
      <TableToolbar
            title=""
            rightComponent={tableRightComponent()}
          />
        {/* {console.log(filteredList)} */}
        {/* {console.log(Rows)} */}
        <ThemeTable
          rows={filterAlerts}
          headCells={Columns}
          hidePagination={false}
          renderRowCell={renderRowCell}
          // selectedRows={selectedRows}
        />
      </div>
    </Container>
  );
}

export default AlertsPage;
