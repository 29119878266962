/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Button,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import confirm from "../../../components/DialogComponent/confirm";
import {
  addIssueResponse,
  getOneIssue,
} from "../../../services/apiservices/issues";
import FileUploadComponent from "../../../components/Fields/FileUploadComponent";
import AttachmentsView from "../../../components/AttachmentsView/AttachmentsView";
import AuthContext from "../../../hooks/authContext";
import CommonContext from "../../../hooks/commonContext";
import ViewAttachments from "./ViewAttachments";
import { getFormatedDate, getFormatedTime } from "../../../services/helpers";
import { DetailsColumns } from "../IssuesManageTableColumns";
import TableToolbar from "../../../components/Table/TableToolbar";
import ThemeTablen from "../../../components/Table/ThemeTablen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faUsers } from "@fortawesome/free-solid-svg-icons";

function ResponseForm(props) {
  const { issueId } = useParams();

  const commonContext = useContext(CommonContext);
  const { userProfile } = useContext(AuthContext);
  const history = useNavigate();
  const [escalated, setescalated] = useState("");
  const [formData, setFormData] = useState({});
  const [errors, setFormError] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [IssueDetail, setIssueDetail] = useState([]);
  const fetchIssuesDetails = async () => {
    if (issueId && issueId !== "") {
      commonContext?.setLoader(true);
      await getOneIssue(
        issueId,
        async (res) => {
          if (res && res.status === true) {
            let details = {
              ...res.data,
              employeeName: res.data?.employee?.name,
              clientName: res.data?.client?.name,
              dateOfRequest: res.data?.dateOfRequest,
            };
            
            let responses =
              details?.responses &&
              [...Object.keys(details?.responses)].map((key) => {
                return { ...details?.responses[key], id: key };
              });
            responses = responses
              ? responses.sort((a, b) => (a.respondOn > b.respondOn ? 1 : -1))
              : [];
            setIssueDetail({ ...details, responses: responses });
            
            commonContext?.setLoader(false);
          } else {
            commonContext?.setLoader(false);
          }
        },
        (resError) => {
          console.log("reserror", resError);
          commonContext?.setLoader(false);
        }
      );
      commonContext?.setLoader(false);
    }
  };
  useEffect(() => {
    fetchIssuesDetails();
  }, [issueId]);

  const oncleardata = () => {
    setFormData({});
    setFormError({});
    setescalated("");
    setAttachments([]);
  };
  const onCancel = async () => {
    await confirm("Are you sure you want to cancel this message ?").then(
      () => {
        oncleardata();
        history("/issues/manage");
      },
      () => {
        console.log("calcel!");
      }
    );
  };

  const checkValid = () => {
    let errormessages = errors;
    let isValid = true;
    if (
      typeof formData?.description === "undefined" ||
      formData?.description === ""
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        description: "Field is required",
      };
    } else {
      errormessages = { ...errormessages, description: "" };
    }

    setFormError(errormessages);
    return isValid;
  };

  const onResponse = async () => {
    if (true === checkValid()) {
      await confirm("Are you sure you want to response this Issue ?").then(
        async () => {
          commonContext?.setLoader(true);
          if (escalated === "Yes") {
            formData.escalated = true;
          }
          if (attachments && attachments.length > 0) {
            formData.attachments = attachments;
          }
          let respondBy = {
            id: userProfile.id,
            name: userProfile.firstName + " " + userProfile.lastName,
          };
          await addIssueResponse(
            IssueDetail.id,
            formData,
            respondBy,
            (res) => {
              oncleardata();
              fetchIssuesDetails();
              commonContext?.setLoader(false);
            },
            (error) => {
              console.log("error", error);
              commonContext?.setLoader(false);
            }
          );
        },
        () => {
          console.log("cancel!");
        }
      );
    }
  };

  const onChangeInput = (e) => {
    const {
      target: { name, value },
    } = e;
    setFormData({ ...formData, [name]: value });
    setFormError({ ...errors, [name]: "" });
  };

  const onFileUpload = (file) => {
    setAttachments([...attachments, file]);
  };
  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {

    let boldclass = rcellItem.status === "Pending" ? "fw-bold" : "";
    switch (hcellItem.id) {
      case "dateOfRequest":
        return (
          <p className={boldclass}>
            {getFormatedDate(rcellItem.dateOfRequest)}{" "}
          </p>
        );
      case "openedOn":
        return (
          <p className={boldclass}>{getFormatedDate(rcellItem.openedOn)} </p>
        );
      case "lastupdated":
        return (
          <p className={boldclass}>{getFormatedDate(rcellItem.updatedAt)} </p>
        );
      case "lastupdatedtime":
        return (
          <p className={boldclass}>{getFormatedTime(rcellItem.updatedAt)} </p>
        );
      case "priority":
        return (
          <p className={boldclass}>
            {rcellItem[hcellItem?.id]}
            {rcellItem[hcellItem?.id] === "High" && (
              <FontAwesomeIcon
                className="color-danger mh-5"
                icon={faExclamation}
              />
            )}
            {rcellItem.escalated_to_admin &&
              Boolean(rcellItem.escalated_to_admin) === true && (
                <FontAwesomeIcon className="mh-5" icon={faUsers} />
              )}
          </p>
        );
      default:
        return <p className={boldclass}>{rcellItem[hcellItem?.id]} </p>;
    }
  };
  return (
    <>
      <div className="mt-15  p-40">
        <TableToolbar
          title=""
          leftComponent={
            <Typography variant="h6">
              Issue #{issueId} - {IssueDetail?.employee?.name}
            </Typography>
          }
          rightComponent={""}
        />
        <ThemeTablen
          rows={[IssueDetail]}
          headCells={DetailsColumns}
          hidePagination={true}
          renderRowCell={renderRowCell}
          // selectedRows={selectedRows}
        />

        <div className=" mt-40">
          <div className="issueDetails mb-20">
            <Typography variant="h6">Issue</Typography>
            <Typography variant="body1">{IssueDetail?.description}</Typography>
          </div>
          <div className="issueDetails mb-20">
            {IssueDetail?.responses &&
              IssueDetail?.responses.map((responseItem, index) => {
                return (
                  <div key={index} className="mb-15">
                    <Typography variant="subtitle1">
                      <em>
                        {responseItem.escalatedTo ? (
                          <>
                            Escalated to{" "}
                            <strong>{responseItem.escalatedTo}</strong> on{" "}
                            {getFormatedDate(responseItem.respondOn)}
                          </>
                        ) : (
                          "Responed On " +
                          getFormatedDate(responseItem.respondOn)
                        )}
                        {responseItem?.respondBy?.name ? <strong> By {responseItem?.respondBy?.name}</strong> : ''}
                      </em>
                    </Typography>
                    <Typography variant="body1">
                      {responseItem.description}
                    </Typography>
                    <ViewAttachments attachments={responseItem.attachments} />
                    <hr />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="issueDetails mb-20">
          <FormControl fullWidth className="mv-15">
            <TextField
              id="outlined-issue-detailsinput"
              label="Message"
              className="bg-white"
              multiline
              rows={4}
              name="description"
              value={formData?.description ? formData.description : ""}
              variant="outlined"
              onChange={onChangeInput}
            />
            {typeof errors?.description !== "undefined" &&
              errors?.description !== "" && (
                <span className="small error color-danger">
                  {errors?.description}
                </span>
              )}
          </FormControl>
          <FormControl fullWidth className="mv-15">
            <FormLabel className="mr-20 strong">Attachments</FormLabel>

            <AttachmentsView attachments={attachments} />
            <FileUploadComponent onuploadfile={onFileUpload} />
          </FormControl>
          <div className="buttonswrap">
            <Button
              variant="contained"
              className="flatbutton themebutton mr-15"
              color="primary"
              onClick={onResponse}
            >
              Response
            </Button>
            <Button
              variant="contained"
              className="flatbutton themebutton mr-15"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResponseForm;
