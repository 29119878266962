/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import {APP_DOMAINURL} from '../../services/config'
import CommonContext from "../../hooks/commonContext";
import { Container } from "@material-ui/core";
import iphoneBg from "../../assets/images/iphoneimage.jpg";
import { useEffect } from "react";
import {
  getEmployee,
} from "../../services/apiservices/employee";

const Employee = (props) => {
  const {employeeId}= useParams();

  return (
    <>
      <Container>
        <div className={`sectionBox`} style={{height:'90vh', justifyContent:'center', display:'flex'}}>
        <iframe src={`${APP_DOMAINURL}${employeeId}`} title="Mobile App" frameBorder="2" style={{borderRadius:10}} width="375" height="100%"></iframe>

        </div>
      </Container>
    </>
  );
};
export default Employee;
