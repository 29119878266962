import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import CircularProgressWithLabel from "../../../components/CircularProgressWithLabel";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import { getDocCount } from "../../../services/apiservices/counters";
import { COLLECTIONS } from "../../../services/config";
import styles from "../dashboard.module.scss";
import moment from "moment/moment";
function Issues(props) {
  const iprogressValue = 60;
  const [iprogress, setIProgress] = useState(0);
  const [status, setStatus] = useState("High");
  const [issueData, setIssueData] = useState([]);
  const [turtype, setTUrtype] = useState([]);
  const [turtypepn, setTUrtypepn] = useState([]);
  const [tnrtype, setTNrtype] = useState([]);
  const [tnrtypepn, setTNrtypepn] = useState([]);
  const [itotalno, setITotalNo] = useState([]);
  const [issuesf, setIssuesf] = useState([]);
  const [filtername, setFilterName] = useState("Urgent ");
  const [tcomtype, setTcomtype] = useState([]);

  const statusList = [
    { label: "Urgent", value: "High" },
    { label: "Normal", value: "Low" },
    { label: "Completed-Today", value: "Completedtoday" },
  ];
  const datefilter = (data, datet) => {
    const filterdata = data?.filter(
      (item) => moment(item.updatedAt).format(moment.HTML5_FMT.DATE) === datet
    );
    return filterdata;
  };

  const onChangeInput = (e) => {
    setStatus(e.target.value);
    if (e.target.value === "High") {
      //setITotalNo(turtype);
      setIssuesf(turtype);
      setFilterName("Urgent ");
      if (issueData.length) {
        updateIProgress((100 / issueData.length) * turtype);
      } else {
        updateIProgress(0);
      }
      
      //console.log(turtype,turtypepn);
    }
    if (e.target.value === "Low") {
      //setITotalNo(tnrtype);
      setIssuesf(tnrtype);
      setFilterName("Normal ");
      if (issueData.length) {
        updateIProgress((100 / issueData.length) * tnrtype);
      } else {
        updateIProgress(0);
      }
      
      //console.log(tnrtype,tnrtypepn)
    }

    if (e.target.value === "Completedtoday") {
      //setITotalNo(tnrtype);
      let todaycom = moment().format(moment.HTML5_FMT.DATE);
      let completedata = datefilter(tcomtype, todaycom);
      // console.log(completedata);
      setIssuesf(completedata.length);
      setFilterName("Completed ");
      if (issueData.length) {
        updateIProgress((100 / issueData.length) * completedata.length);
      } else {
        updateIProgress(0);
      }
      
      //console.log(tnrtype,tnrtypepn)
    }
  };

  const updateIProgress = (newval) => {
    setIProgress(newval);
  };
  React.useEffect(() => {
    getDocCount(
      COLLECTIONS.ISSUES,
      (res) => {
        // console.log("res", res);

        updateIProgress(100 / res.length);
        const urtype = res.filter((item) => item.priority === "High");
        const nrtype = res.filter((item) => item.priority === "Low");
        const comtype = res.filter((item) => item.status === "Closed");
        const comtypett = res.filter((item) => item.status !== "Closed");
        const comtypettres = res.filter((item) => item.status === "Resolved");
        setIssueData(comtypett);
        // console.log(comtypettres);
        setTUrtype(urtype.length);
        setTNrtype(nrtype.length);
        setTcomtype(comtype);

        setITotalNo(comtypett.length);
        setIssuesf(urtype.length);
        if (comtypett.length) {
          updateIProgress((100 / comtypett.length) * urtype.length);
        } else {
          updateIProgress(0);
        }
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
  }, []);

  //const unreadno = issueData.filter(item => item.status === 'Pending');

  //console.log("total",unreadno);
  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12} sm={7}>
          <p className="p-0 m-0 mb-5 fonthe">Issues</p>
          <SingleSelect
            //   label="status"
            value={status}
            className={`field newonr`}
            name={"status"}
            onChange={(e) => onChangeInput(e)}
            options={statusList}
          />
        </Grid>
        <Grid item xs={12} sm={5} className="text-right">
          <CircularProgressWithLabel
            signt={"yes"}
            value={iprogress}
            wrapclass="color-iss"
          />
        </Grid>
      </Grid>
      <ul>
        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              Total Issues
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{itotalno ? itotalno : 0}</strong>
            </Grid>
          </Grid>
        </li>
        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              {filtername}Issues
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{issuesf ? issuesf : 0}</strong>
            </Grid>
          </Grid>
        </li>

        {/* <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              Completed
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{issueData?.completed ? issueData?.completed : 0}</strong>
            </Grid>
          </Grid>
        </li> */}
      </ul>
    </>
  );
}

export default Issues;
