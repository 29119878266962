import { firebase } from "../firebase-config";
import { COLLECTIONS } from "../config";
const collection = COLLECTIONS.STANDARDTAKS;
/**
 * Get Tasks list from standardtasks collection (shown while create client)
 * @param {*} formData 
 * @param {*} onSuccess 
 * @param {*} onError 
 */
export const getStandardTasksList = (formData, onSuccess, onError) => {
  let colRef = firebase.firestore().collection(collection);
  colRef
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ taskId: doc.id, ...data });
      });
      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
      onError && onError(error);
    });
};
/**
 * Get Task by id
 */
export async function getStandardTask(id, onSuccess, onError) {
  let colRef = firebase.firestore().collection(collection);
  try {
    colRef
      .doc(id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          onError && onError({ status: false, error: "Task not found" });
        }
        let data = doc.data();
        onSuccess &&
          onSuccess({ status: true, data: { taskId: doc.id, ...data } });
      })
      .catch((error) => {
        onError && onError({ status: false, error: error });
      });
  } catch (res_1) {
    onError && onError(res_1);
  }
}