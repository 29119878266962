import React, { useState } from "react";
import { Container } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import { Link } from "react-router-dom";
import ThemeTable from "../../../components/Table/ThemeTable";
import styles from '../Report.module.scss'

function ClientReport(props) {

  const [filterReports, setfilterReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const reportList = [
    { "name": "Shift Reports", "id": "Create", Link: "/reports/shiftreport" },
    { "name": "Tasks Reports", "id": "Create", Link: "/reports/taskreport" },
    { "name": "Work Site Reports", "id": "Create", Link: "/reports/worksitereport" },
    { "name": "Issue Reports", "id": "Create", Link: "/" },
    { "name": "Supply Reports", "id": "Create", Link: "/" },
  ]

  const getColumns = () => {
    const columns = [];
    columns.push({
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Report Name",
      isSortable: false,
      rowclassName: styles.width25,
    });
    columns.push({
      id: "Action",
      numeric: false,
      disablePadding: false,
      label: "Action",
      isSortable: false,
    });
    return columns;
  };

  useState(() => {
    setfilterReports(reportList);
  }, []);

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case 'Action':
        if (rcellItem.id === "Create") {
          return (
            <div className={styles.actions} >
              <Link to={rcellItem.Link} rel="noopener" className={` ${styles.actions} ${styles.textunderline}`}>
                Create
              </Link>
            </div>
          )
        }
        break;
      default:
        return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
    }
  }

  return (
    <>

     <Container style={{ boxSizing: "content-box", padding:0}} >
        <PageTitle
          pageTitle="Client Reports"
          showBreadcrumb={[{ label: "Home", to: "/" }, { label: "Reports" }]}
          BreadCrumbCurrentPageTitle={"Client Reports"}
          showLastSeen={true}
        ></PageTitle>


        <div className={`sectionBox summaryTableWrap ${styles.sectionbox}`} >
          <div className={styles.border}>
            <ThemeTable
              rows={filterReports}
              headCells={getColumns()}
              hidePagination={true}
              isLoading={isLoading}
              renderRowCell={renderRowCell}
            />
          </div>
        </div>
      </Container>
    </>
  )

}

export default ClientReport;