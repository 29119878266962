import axiosInstance from "../axios";
import { getLoginToken } from "../storage";
import { BaseUrl, COLLECTIONS } from "../config";
import { firebase } from "../firebase-config";

const defaultHeaders = {
  "Content-Type": "application/json",
  withCredentials: true,
};
let usercollection = COLLECTIONS.USERS;
/**
 * unique validate user by email and phone number
 * @param {*} formData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function validateUniqueUser(formData, onSuccess, onError) {
  const userRef = await firebase.firestore().collection(usercollection);
  let responseObj = {};
  let isvalid = true;
  if (formData.email && formData.email !== "") {
    const snapshot = await userRef.where("email", "==", formData.email).get();
    // console.log(snapshot[0].data());
    let userInfo = {};
    snapshot.forEach((dd) => {
      userInfo = dd.data();
    });
    
    if (
      (!snapshot.empty && !formData.userId) ||
      (!snapshot.empty &&
        formData.userId &&
        formData.userId !== userInfo.userId)
    ) {
      responseObj = { ...responseObj, email: "Email Already Registered" };
      isvalid = false;
    }
  }
  if (formData.mobile && formData.mobile !== "") {
    const snapshotmobile = await userRef
      .where("phoneNumber", "==", formData.mobile)
      .get();
    let userInfo = {};
    snapshotmobile.forEach((dd) => {
      userInfo = dd.data();
    });
    if (
      (!snapshotmobile.empty && !formData.userId) ||
      (!snapshotmobile.empty &&
        formData.userId &&
        formData.userId !== userInfo.userId)
    ) {
      isvalid = false;
      responseObj = {
        ...responseObj,
        mobile: "Mobile Number Already Registered",
        phoneNumber: "Mobile Number Already Registered",
      };
    }
  }
  onSuccess({ status: isvalid, data: responseObj });
}

/**
 * Get list of client users (Not used after restructured)
 * @param {*} formData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getClientList(formData, onSuccess, onError) {
  const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
  try {
    const res = await axiosInstance.get(`${BaseUrl}/clients`, {
      params: { ...formData },
      headers: { ...defaultHeaders, ...authHeaders },
    });
    onSuccess && onSuccess(res);
  } catch (res_1) {
    onError && onError(res_1);
  }
}


/**
 * Get list of users (Not used after restructured)
 * @param {*} formData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getUserListBasedOnIds(userIDs, onSuccess, onError) {
  const chunk = (arr, chunkSize) => {
    var R = [];
    for (var i = 0; i < arr.length; i += chunkSize)
      R.push(arr.slice(i, i + chunkSize));
    return R;
  };
  let colRef = firebase.firestore().collection(usercollection);
  
  const usersNew = [];
  const result = await Promise.all(
    chunk(userIDs, 10).map(async (chunkIds) => {
      const accounts = await colRef
        .where(firebase.firestore.FieldPath.documentId(), 'in', [
          ...chunkIds,
        ])
        .get()
        .then((snap) => {
          const cli = [];
          snap.forEach((doc) => {
            //console.log('getClientsById', { ...doc.data(), id: doc.id });
            usersNew.push({ ...doc.data(), id: doc.id });
            cli.push({ ...doc.data(), id: doc.id });
          });
          return cli;
        }).catch((error) => {
          console.log('Error getting documents: ', error);
      onError && onError(error);
        });
        return accounts;
    })
  );
  onSuccess && onSuccess(usersNew);
  
}