import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

// Function to download the Excel file
export const downloadExcel = (clientCSV, csvHeaders, wb, ws, fileName, emailSend, name, fullpage) => {
  return new Promise((resolve, reject) => {

    let file = fileName.split("_");
    let nameList = excelNames(name, file, ws);

    ws.addRow([]);

    if (fullpage) {
      ws.addRow(csvHeaders);

      clientCSV.forEach(row => {
        ws.addRow(row);
      });

      ws.mergeCells('A1:H1');
      ws.mergeCells('A2:H2');
      ws.mergeCells('A3:H3');

      ws.columns.forEach(column => {
        column.width = 8;
        column.alignment = { wrapText: true };
      });
    } else {
      const finalExcelData = [
        [...csvHeaders],
        ...clientCSV,
      ];

      finalExcelData.forEach((row, index) => {
        ws.addRow(row);
      });

      ws.mergeCells('A1:H1');
      ws.mergeCells('A2:H2');
      ws.mergeCells('A3:H3');
    }

    wb.xlsx.writeBuffer().then(async (excelBuffer) => {
      let v = base64DownloaderMiscWeb(excelBuffer, fileName, emailSend);
      resolve(v);
    }).catch(error => {
      reject(error);
    });
  });
};


const excelNames = (name, file, ws) => {
  if (name) {
    ws.getCell('A1').value = `Employee Name: ${file[1]}`;
    ws.getCell('A1').font = { bold: true };

    ws.getCell('A2').value = `Report Name: ${file[0]}`;
    ws.getCell('A2').font = { bold: true };

    const reportPeriod = file[2] ? (file[3] ? `Report Period: ${file[2]} to ${file[3]}` : `Report Period: ${file[2]}`) : 'Report Period: N/A';
    ws.getCell('A3').value = reportPeriod;
    ws.getCell('A3').font = { bold: true };
  } else {
    ws.getCell('A1').value = `Client Name: ${file[1]}`;
    ws.getCell('A1').font = { bold: true };

    ws.getCell('A2').value = `Report Name: ${file[0]}`;
    ws.getCell('A2').font = { bold: true };

    const reportPeriod = file[2] ? (file[3] ? `Report Period: ${file[2]} to ${file[3]}` : `Report Period: ${file[2]}`) : 'Report Period: N/A';
    ws.getCell('A3').value = reportPeriod;
    ws.getCell('A3').font = { bold: true };
  }
}

const base64DownloaderMiscWeb = (excelBuffer, fileName, emailSend) => {
  if (emailSend) {
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    return blob;
  }
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const linkSource = URL.createObjectURL(blob);
  const downloadLink = document?.createElement('a')
    ? document?.createElement('a')
    : { href: '', download: '', click: () => true };
  downloadLink.href = linkSource;
  downloadLink.download = `${fileName}.xlsx`;
  downloadLink.click();

  return null;
};


export const downloadPDF = (clientCSV, csvHeaders, fileName, emailSend, name, fullpage) => {
  let doc;

  if (fullpage) {

    const headerCount = csvHeaders.length;
    let pageWidth, pageHeight, columnWidth, cellPadding;

   if (headerCount <= 48) {
      pageWidth = 500;
      pageHeight = 350;
      columnWidth -= 1;
      cellPadding = 1;
    }
    else if (headerCount > 49 && headerCount <= 75) {
      pageWidth = 1000;
      pageHeight = 650;
      columnWidth -= 1;
      cellPadding = 0.5;
    }
    else if (headerCount > 76 && headerCount <= 130){
      pageWidth = 1500;
      pageHeight = 1000;
      columnWidth -= 1;
      cellPadding = 0.5;
    } else if (headerCount > 131 && headerCount <= 170){
      pageWidth = 2250;
      pageHeight = 1250;
      columnWidth -= 1;
      cellPadding = 0.5;
    }else {
      columnWidth -= 1;
      pageWidth = 2700;
      pageHeight = 1550;
    }

    doc = new jsPDF('l', 'mm', [pageHeight, pageWidth]);

    let file = fileName.split("_");
    let nameList = names(name, file, doc);

    autoTable(doc, {
      startY: 50,
      head: [[...csvHeaders]],
      body: clientCSV,
      tableWidth: 'wrap',
      styles: {
        fontSize: headerCount > 40 ? 5 : 8,
        halign: 'center'
      },
      headStyles: {
        fontSize: headerCount > 40 ? 5 : 8,
        fillColor: [41, 128, 186],
      },
      margin: { top: 40, left: 5, right: 5, bottom: 10 },
      theme: 'grid'
    });

  } else {
    doc = new jsPDF();
    let file = fileName.split("_");
    let nameList = names(name, file, doc);

    autoTable(doc, {
      startY: 50,
      head: [[...csvHeaders]],
      body: clientCSV,
      styles: {
        halign: 'center'
      }
    });
  }

  if (emailSend) {
    const pdfContent = doc.output('dataurlstring');
    return pdfContent;
  } else {
    doc.save(`${fileName}.pdf`);
  }
};

const names = (name, file, doc) => {
  if (name) {
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`Employee Name : ${file[1]} Employee`, 14, 20);

    doc.setFont('helvetica', 'bold');
    doc.text(`Report Name : ${file[0]}`, 14, 28);

    doc.setFont('helvetica', 'bold');
    const reportPeriod = file[2] ? (file[3] ? `Report Period : ${file[2]} to ${file[3]}` : `Report Period : ${file[2]}`) : 'Report Period : N/A';
    doc.text(reportPeriod, 14, 36);
  } else {
    // Add other details if needed
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`Client Name : ${file[1]} Client`, 14, 20);

    doc.setFont('helvetica', 'bold');
    doc.text(`Report Name : ${file[0]}`, 14, 28);

    doc.setFont('helvetica', 'bold');
    const reportPeriod = file[2] ? (file[3] ? `Report Period : ${file[2]} to ${file[3]}` : `Report Period : ${file[2]}`) : 'Report Period : N/A';
    doc.text(reportPeriod, 14, 36);
  }
}

