/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CommonContext from "../../hooks/commonContext";
import { Container, Button, TextField, IconButton } from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import ThemeTable from "../../components/Table/ThemeTable";
import { Columns } from "./clientsTableColumns";
import TableToolbar from "../../components/Table/TableToolbar";

import EditIcon from "@material-ui/icons/Edit";
import { useEffect } from "react";
import {
  getClientsList,
  updateClient,
} from "../../services/apiservices/client";

const Clients = (props) => {
  const commonContext = useContext(CommonContext);
  const history = useNavigate();
  const [allClients, setAllClients] = React.useState([]);
  const [searchTerm, setsearchTerm] = React.useState("");
  const fetchClientsList = async () => {
    commonContext?.setLoader(true);
    getClientsList(
      {},
      (res) => {
        let setallclientData = [
          ...res?.map((rcitem) => {
            let primarycontact = {};
            if (rcitem?.contact && rcitem?.contact.length > 0) {
              primarycontact = rcitem?.contact.filter((citem) => citem?.type === 'primary');
              primarycontact = primarycontact?.length > 0 ? primarycontact[0] : {};
            }
            return {
              ...rcitem,
              clientId:rcitem.id,
              primaryContact: primarycontact?.mobileNumber,
              email: primarycontact?.email,
            };
          }),
        ];
        setAllClients(setallclientData);
        commonContext?.setLoader(false);
      },
      (resError) => {
        console.log("reserror", resError);
        commonContext?.setLoader(false);
      }
    );
  };
  useEffect(() => {
    fetchClientsList();
  }, []);

  const redirectToCreateClient = (clientId) => {
    if (typeof clientId !== "undefined" && clientId !== "")
      history("/createClient/" + clientId);
    else history("/createClient");
  };
  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "shiftCount":
        return <>{rcellItem?.shiftData ? [...Object.keys(rcellItem.shiftData)].length : ''}</>;
      case "action":
        return (
          <>
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              onClick={() => redirectToCreateClient(rcellItem?.clientId)}
            >
              <EditIcon />
            </IconButton>
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  const tableRightComponent = () => {
    return (
      <div className="d-flex groupsearch">
        <div className=" mh-9">
          <TextField
            fullWidth={true}
            size="small"
            name="searchTerm"
            id="searchTerm"
            label=""
            variant="outlined"
            placeholder="Search Client..."
            className="input"
            onChange={(event) => {
              setsearchTerm(event.target.value);
            }}
            value={searchTerm ? searchTerm : ""}
          />
        </div>
        <Button
          className="flatbutton w-norm"
          size={"small"}
          variant="contained"
          color="primary"
          onClick={() => redirectToCreateClient()}
        >
          Add New Client
        </Button>
      </div>
    );
  };

  const OnSwitchChange = (rowitem, newstatus) => {
    commonContext?.setLoader(true);
    updateClient(
      rowitem?.clientId,
      { status: newstatus },
      (res) => {
        if (res?.data?.status === true) {
          commonContext?.setLoader(false);
          fetchClientsList();
        } else {
          commonContext?.setLoader(false);
        }
      },
      (resError) => {
        commonContext?.setLoader(false);
        fetchClientsList();
      }
    );
  };
  const checkRowSearch = (searchTerm, row) => {
    return (
      String(row.clientId)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.email)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.clientName)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.primaryContact)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase())
    );
  };
  const filteredList =
    searchTerm !== ""
      ? allClients?.filter((item) => checkRowSearch(searchTerm, item))
      : allClients;
  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Clients"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Clients", to: "/" },
          ]}
          BreadCrumbCurrentPageTitle={"Manage Clients"}
          showLastSeen={true}
        />

        <div className={`sectionBox`}>
          <TableToolbar
            title="Clients"
            rightComponent={tableRightComponent()}
          />
          <ThemeTable
            rows={filteredList}
            headCells={Columns}
            hidePagination={true}
            renderRowCell={renderRowCell}
            // selectedRows={selectedRows}
            OnSwitchChange={OnSwitchChange}
          />
        </div>
      </Container>
    </>
  );
};
export default Clients;
