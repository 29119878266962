import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../components/TabPanel";
import ShiftSummaryList from "./ShiftSummary/ShiftSummaryList";
import { getUniqueArray } from "../../../services/helpers";
import TaskSummaryList from "./ShiftSummary/TaskSummaryList";
const AntTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: "transparent",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#000",
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: theme.typography.fontWeightMedium,
      background: "#E3E3E3",
    },
    "&:focus": {
      color: "#000",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
  shiftTitle: {
    display: "flex",
    backgroundColor: "#2e1534",
  },
}));

export default function TaskSummary(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let SummaryShiftListarr = props?.SummaryList?.map(
    (sitem) => sitem?.shift?.title
  );
  //SummaryShiftListarr = getUniqueArray(SummaryShiftListarr);
  let SummaryFrequencyListarr = props?.SummaryList?.map(
    (sitem) => sitem?.frequency
  );
  SummaryFrequencyListarr = getUniqueArray(SummaryFrequencyListarr);

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="Frequency Summary" />
          {/* <AntTab label="Shift Summary" /> */}
          
        </AntTabs>
        {/* <TabPanel value={value} index={1}>
          {props?.SummaryList && props?.SummaryList?.length > 0 && (
            <ShiftSummaryList
              SummaryList={props?.shift}
              SummaryShiftListarr={SummaryShiftListarr}
              {...props}
            />
          )}
        </TabPanel> */}
        <TabPanel value={value} index={0}>
          {props?.SummaryList && props?.SummaryList?.length > 0 && (
            <TaskSummaryList
              SummaryList={props?.shift}
              SummaryFrequencyListarr={SummaryFrequencyListarr}
              {...props}
            />
          )}
        </TabPanel>
      </div>
    </div>
  );
}
